import React, { useEffect, useState } from "react";
import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts';
import { getHourlyRevenueData } from "../../services/firestoreService"; // Update the service function to fetch hourly data
import AnalyticsIcon from "../../assets/icons/AnalyticsBlack.svg";
import LoadingSpinner from "../common/Loading";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white px-3 w-fit h-fit py-2 shadow-lg rounded-xl border border-gray-200">
        <p className="font-semibold text-xl text-slate-600">{label}:00 - {label}:59</p>
        <p className={""}>
          <div className={"w-full h-fit flex justify-between"}>
            <h1 className={"font-medium text-sm text-slate-300"}>Revenue:</h1>
            <h1 className={"font-medium text-sm text-green-700"}>${payload[0].value.toFixed(2)}</h1>
          </div>
        </p>
      </div>
    );
  }

  return null;
};

const RevenueWidgetD3 = ({ orgId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getHourlyRevenueData(orgId); // Pass the selected date

        // Initialize an array for 24 hours with zero values
        const completeData = Array.from({ length: 24 }, (_, i) => ({
          hour: i.toString().padStart(2, '0'),
          income: 0,
          spend: 0,
          transactions: 0, // Assuming you have a transactions field
        }));

        if (result && typeof result === 'object' && !Array.isArray(result)) {
          // Map the result data to the completeData array
          Object.keys(result).forEach(hour => {
            const hourIndex = parseInt(hour, 10);
            completeData[hourIndex] = {
              hour: hour.toString().padStart(2, '0'),
              ...result[hour],
            };
          });

          // Calculate total profit and total transactions
          const totalProfit = completeData.reduce((acc, item) => acc + item.income, 0);
          const totalTransactions = completeData.reduce((acc, item) => acc + (item.transactions || 0), 0);

          setTotalProfit(totalProfit);
          setTotalTransactions(totalTransactions);
        } else {
          console.error("Received data is not in expected format:", result);
        }

        setData(completeData);
      } catch (error) {
        console.error("Error fetching revenue data:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of the outcome
      }
    };

    fetchData();
  }, [orgId]);

  if (loading) {
    return (
      <div className={"w-full xl:h-96 h-120 rounded-lg shadow-lg bg-white mt-12 flex justify-center items-center"}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className={"w-full h-96 rounded-lg shadow-lg bg-white mt-12 flex flex-col overflow-clip"}>
      <div className={"h-10 w-full flex justify-between p-4"}>
        <div className={"w-fit h-fit flex items-center space-x-2"}>
          <h1 className={"font-medium p-1.5 bg-slate-100 rounded text-slate-600"}>${totalProfit.toFixed(2)} Profit</h1>
          <h1 className={"font-medium p-1.5 bg-slate-100 rounded text-slate-600"}>{totalTransactions} Transactions</h1>
        </div>
        <div className={"w-fit h-fit flex"}>
          <h1 className={"font-medium"}>Cashflow</h1>
          <h1 className={"opacity-30 font-medium mx-1.5"}>•</h1>
          <div className={"group flex cursor-pointer"}>
            <img className={"opacity-30 group-hover:opacity-100 transition"} src={AnalyticsIcon} alt={"Revenue"}/>
            <h1 className={"opacity-30 group-hover:opacity-100 transition font-medium"}>Analytics</h1>
          </div>
        </div>
      </div>
      <div className={"w-full flex-grow transition"}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorIncome" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#93c5fd" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#93c5fd" stopOpacity={0.1}/>
              </linearGradient>
            </defs>
            <Tooltip content={<CustomTooltip />} />
            <Area type="monotone" dataKey="income" stroke="#3b82f6" fillOpacity={1} fill="url(#colorIncome)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default RevenueWidgetD3;
