import React, { useEffect, useState } from 'react';
import SelectSites from '../specific/SelectSites';
import { fetchOrganizationData } from '../../services/firestoreService';
import { getDashboardData } from '../../services/analyticsService';
import SearchContainer from "../../components/layout/SearchComponent";
import CalendarIcon from "../../assets/icons/CalendarIconBlack.svg";

import OwnerIcon from "../../assets/icons/OwnerIcon.svg"
import StudentIcon from "../../assets/icons/StudentIcon.svg"
import GuestIcon from "../../assets/icons/PublicIcon.svg"
import {PieChart, Area, AreaChart, Cell, Pie, ResponsiveContainer, Tooltip} from "recharts";
import LoadingSpinner from "../common/Loading";
import {Legend} from "chart.js";

const CashflowTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white px-3 w-fit h-fit py-2 shadow-lg rounded-xl border border-gray-200">
        <p className="font-semibold text-xl text-slate-600">{label}:00 - {label}:59</p>
        <p className={""}>
          <div className={"w-full h-fit flex justify-between space-x-2"}>
            <h1 className={"font-medium text-sm text-slate-300"}>Revenue:</h1>
            <h1 className={"font-medium text-sm text-green-700"}>${payload[0].value.toFixed(2)}</h1>
          </div>
        </p>
      </div>
    );
  }

  return null;
};


const AnalyticsBoard = ({ orgId }) => {
    const [loading, setLoading] = useState(true);
    const [selectedSites, setSelectedSites] = useState([]);
    const [dashboardData, setDashboardData] = useState({
        totalIncome: 0,
        totalExpenditures: 0,
        averageTransactionValue: 0,
        transactionCount: 0,
        customerTypeBreakdown: {},
        cashflowArray: {},
    });

    const customerIcons = {
        Owner: OwnerIcon,
        Student: StudentIcon,
        Guest: GuestIcon,
    };

    const customerColors = {
        Owner: "bg-primary bg-opacity-30",
        Student: "bg-red-500 bg-opacity-30",
        Guest: "bg-emerald-500 bg-opacity-30"
    };
    const customerPieColor = {
        Owner: "#ff0000",
        Student: "#00ff00",
        Guest: "#0000ff"
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDashboardData(orgId);
                setDashboardData(data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch dashboard data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [orgId]);

    const handleSiteSelect = (selectedOptions) => {
        setSelectedSites(selectedOptions);
    };

    const totalTransactions = Object.values(dashboardData.customerTypeBreakdown).reduce((sum, num) => sum + num, 0);

    if (loading) {
        return <div className={"flex justify-center items-center h-full"}>
            <LoadingSpinner />
        </div>;
    }

    return (
        <div className="w-full h-full flex flex-col">
            <div className="mb-4 flex justify-between w-full">
                <SelectSites orgId={orgId} onSelect={handleSiteSelect} />
                <div className="w-fit h-fit flex space-x-3">
                    <div className="group cursor-pointer w-fit pr-4 h-10 border font-medium text-black text-xs rounded-lg flex space-x-2 items-center p-2">
                        <img className="h-5 aspect-square opacity-40 group-hover:opacity-80 transition" src={CalendarIcon} alt="Calendar"/>
                        <h1>All Time</h1>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full flex-grow space-y-4">
                <div className="flex space-x-4 w-full h-20">
                    <div className="flex-1 p-3 bg-white shadow rounded-xl flex items-center">
                        <div className="h-fit">
                            <h2 className="text-sm font-medium opacity-40">Revenue</h2>
                            <h1 className="text-2xl font-semibold">${dashboardData.totalIncome.toFixed(2)}</h1>
                        </div>
                    </div>
                    <div className="flex-1 p-3 bg-white shadow rounded-xl flex items-center">
                        <div className="h-fit">
                            <h2 className="text-sm font-medium opacity-40">Expenditures</h2>
                            <h1 className="text-2xl font-semibold">${dashboardData.totalExpenditures.toFixed(2)}</h1>
                        </div>
                    </div>
                    <div className="flex-1 p-3 bg-white shadow rounded-xl flex items-center">
                        <div className="h-fit">
                            <h2 className="text-sm font-medium opacity-40">Avg. Payment</h2>
                            <h1 className="text-2xl font-semibold">${dashboardData.averageTransactionValue.toFixed(2)}</h1>
                        </div>
                    </div>
                    <div className="flex-1 p-3 bg-white shadow rounded-xl flex items-center">
                        <div className="h-fit">
                            <h2 className="text-sm font-medium opacity-40">Total Payments</h2>
                            <h1 className="text-2xl font-semibold">{dashboardData.transactionCount}</h1>
                        </div>
                    </div>
                </div>
                <div className="flex space-x-4 w-full flex-grow">
                    <div className="w-3/5 bg-white shadow rounded-xl h-full flex flex-col">
                        <h2 className="text-lg font-medium px-4 pt-2">Cashflow</h2>
                        <div className={"w-full flex-grow transition"}>
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart data={dashboardData.cashflowArray} margin={{top: 20, right: 0, left: 0, bottom: 0}}>
                                    <defs>
                                        <linearGradient id="colorIncome" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#93c5fd" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#93c5fd" stopOpacity={0.1}/>
                                        </linearGradient>
                                    </defs>
                                    <Tooltip content={<CashflowTooltip />} />
                                    <Area type="monotone" dataKey="income" stroke="#3b82f6" fillOpacity={1}
                                          fill="url(#colorIncome)"/>
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div className="w-2/5 p-4 bg-white shadow rounded-xl h-full flex flex-col space-y-4">
                        <h2 className="text-lg font-medium h-fit">Transaction Traffic By Location</h2>
                        <div className="space-y-2.5 w-full flex-grow flex flex-col">
                            {Object.entries(dashboardData.siteTransactionTrafficBreakdown).map(([type, count]) => {
                                const widthPercent = (count / totalTransactions) * 100;
                                const barColor = "bg-blue-500 bg-opacity-40"; // Default color if type is undefined

                                return (
                                    <div key={type} className="relative w-full h-full">
                                        <div className="w-full bg-slate-100 rounded-xl h-full flex justify-between items-center">
                                            <div className={`${barColor} h-full rounded-xl flex items-center justify-between`}
                                                 style={{width: `${widthPercent}%`}}>
                                                <div className={"flex pl-2"}>
                                                    <div className="text-sm font-medium py-1 px-2 text-blue-900 rounded-full">{type}</div>
                                                </div>
                                            </div>
                                            <span className="ml-2 text-black font-medium text-xs mr-2">{((count / totalTransactions) * 100).toFixed(1)}%</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="flex space-x-4 w-full flex-grow">
                    <div className="w-2/5 p-4 bg-white shadow rounded-xl h-full flex flex-col space-y-4">
                        <h2 className="text-lg font-medium h-fit">Revenue By Customer</h2>
                        <div className="space-y-2.5 w-full flex-grow flex flex-col">
                            {Object.entries(dashboardData.customerTypeBreakdown).map(([type, count]) => {
                                const widthPercent = (count / totalTransactions) * 100;
                                const Icon = customerIcons[type] || OwnerIcon; // Default to OwnerIcon if no specific icon
                                const barColor = customerColors[type] || "bg-blue-500 opacity-40"; // Default color if type is undefined

                                return (
                                    <div key={type} className="relative w-full h-full">
                                        <div
                                            className="w-full bg-slate-100 rounded-xl h-full flex justify-between items-center">
                                            <div
                                                className={`${barColor} h-full rounded-xl flex items-center justify-between`}
                                                style={{width: `${widthPercent}%`}}>
                                                <div className={"flex space-x-2"}>
                                                    <img src={Icon} alt={type} className="h-5 w-5 ml-2"/>
                                                    <div className="text-sm font-medium">{type}</div>
                                                </div>
                                            </div>
                                            <span
                                                className="ml-2 text-black font-medium text-xs mr-2">{((count / totalTransactions) * 100).toFixed(1)}%</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="w-3/5 bg-white shadow rounded-xl h-full flex flex-col">
                        <h2 className="text-lg font-medium px-4 pt-2">Avg. Transaction Value</h2>
                        <div className={"w-full flex-grow transition"}>
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart data={dashboardData.cashflowArray}
                                           margin={{top: 20, right: 0, left: 0, bottom: 0}}>
                                    <defs>
                                        <linearGradient id="colorIncome" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#93c5fd" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#93c5fd" stopOpacity={0.1}/>
                                        </linearGradient>
                                    </defs>
                                    <Tooltip/>
                                    <Area type="monotone" dataKey="income" stroke="#3b82f6" fillOpacity={1}
                                          fill="url(#colorIncome)"/>
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsBoard;
