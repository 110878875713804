import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/App/Dashboard";
import './tailwind.css';
import Signup from "./pages/SignUp/SignUp";
import Security from "./pages/Products/Security";
import Payments from "./pages/Products/Payments";
import Platform from "./pages/Products/Platform";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/security" element={<Security />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/platform" element={<Platform />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
                <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
