import React, { useState } from 'react';

const BillingHistoryTable = () => {
    const [billingData, setBillingData] = useState([
        { date: '2023-06-01', invoiceNumber: 'INV-001', amount: '$50.00', status: 'Paid' },
        { date: '2023-05-01', invoiceNumber: 'INV-002', amount: '$50.00', status: 'Paid' },
        { date: '2023-04-01', invoiceNumber: 'INV-003', amount: '$50.00', status: 'Paid' },
        { date: '2023-03-01', invoiceNumber: 'INV-004', amount: '$50.00', status: 'Paid' },
        { date: '2023-02-01', invoiceNumber: 'INV-005', amount: '$50.00', status: 'Paid' },
        { date: '2023-01-01', invoiceNumber: 'INV-006', amount: '$50.00', status: 'Paid' },
        { date: '2023-01-01', invoiceNumber: 'INV-006', amount: '$50.00', status: 'Paid' },
        { date: '2023-01-01', invoiceNumber: 'INV-006', amount: '$50.00', status: 'Paid' },
        { date: '2023-01-01', invoiceNumber: 'INV-006', amount: '$50.00', status: 'Paid' },
        // Add more rows as needed
    ]);

    return (
        <div className="w-full h-96 py-4 flex flex-col">
            <h1 className="text-2xl font-medium mb-4">Payment History</h1>
            <div className="flex flex-col flex-grow overflow-hidden">
                <div className="overflow-auto flex-grow border rounded-lg">
                    <table className="min-w-full bg-white overflow-clip">
                        <thead className="sticky top-0 bg-slate-100 mb-4">
                            <tr>
                                <th className="py-2 px-4 border-b text-start">Date</th>
                                <th className="py-2 px-4 border-b text-start">Invoice Number</th>
                                <th className="py-2 px-4 border-b text-start">Amount</th>
                                <th className="py-2 px-4 border-b text-start">Status</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-y-auto h-64"> {/* Set a fixed height */}
                            {billingData.map((item, index) => (
                                <tr key={index} className="text-gray-700">
                                    <td className="py-4 px-4 border-b">{item.date}</td>
                                    <td className="py-4 px-4 border-b">{item.invoiceNumber}</td>
                                    <td className="py-4 px-4 border-b">{item.amount}</td>
                                    <td className="py-4 px-4 border-b">{item.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default BillingHistoryTable;




