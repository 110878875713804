import React, { useState, useEffect } from 'react';
import {
  BarChart, Bar, Tooltip, ResponsiveContainer
} from 'recharts';
import {
  fetchAllSecurityEntriesForOrg,
  filterEntriesFromToday
} from '../../services/firestoreService'; // Adjust the import path based on your file structure
import AnalyticsIcon from "../../assets/icons/AnalyticsBlack.svg";
import LoadingSpinner from "../common/Loading";

const CustomBarTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white px-3 w-fit h-fit py-2 shadow-lg rounded-xl border border-gray-200">
        <p className="font-semibold text-xl text-slate-600">{label}:00 - {label}:59</p>
        {payload.map((entry, index) => (
          <div className="w-full h-fit flex justify-between items-center" key={index}>
            <div className="flex items-center">
              <div
                className="w-2 h-2 rounded-full"
                style={{ backgroundColor: entry.color }}
              ></div>
              <h1 className="font-medium text-sm text-slate-300 ml-2">{entry.name}:</h1>
            </div>
            <h1 className="font-medium text-sm text-slate-700">{entry.value}</h1>
          </div>
        ))}
      </div>
    );
  }

  return null;
};


const TrafficStackedBarChart = ({ orgId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [entryCount, setEntryCount] = useState(0); // State to track the number of entries

  // Fetch data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        let entries = await fetchAllSecurityEntriesForOrg(orgId);
        entries = filterEntriesFromToday(entries)
        const currentTime = new Date();

        // Initialize an array to store counts per hour
        const hourBuckets = Array.from({ length: 24 }, (_, i) => ({
          hour: `${String(i).padStart(2, '0')}:00`,
          Guest: 0,
          Student: 0,
          Owner: 0,
        }));

        // Process each entry and increment the respective hour bucket
        entries.forEach(entry => {
          const entryTime = entry.timestamp.toDate();
          const hour = entryTime.getHours();

          if (entry.AccessLevel === "Guest") {
            hourBuckets[hour].Guest++;
          } else if (entry.AccessLevel === "Student") {
            hourBuckets[hour].Student++;
          } else if (entry.AccessLevel === "Owner") {
            hourBuckets[hour].Owner++;
          }
        });

        setData(hourBuckets);
        setEntryCount(entries.length); // Set the number of entries
        setLoading(false);
      } catch (error) {
        console.error('Error fetching security entries:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [orgId]);

  if (loading) {
    return (
      <div className="w-full xl:h-96 h-120 rounded-lg shadow-lg bg-white mt-12 flex justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className={"w-full xl:h-96 h-120 rounded-lg shadow-lg bg-white mt-12 flex flex-col overflow-clip"}>
      <div className={"h-10 w-full flex justify-between p-4"}>
        <div className={"w-fit h-fit flex items-center space-x-2"}>
          <h1 className={"font-medium p-1.5 bg-red-100 rounded"}>High Traffic</h1>
          <h1 className={"font-medium p-1.5 bg-slate-100 rounded"}>{entryCount} Entries</h1> {/* Use entryCount here */}
        </div>
        <div className={"w-fit h-fit flex"}>
          <h1 className={"font-medium"}>Lock Traffic</h1>
          <h1 className={"opacity-30 font-medium mx-1.5"}>•</h1>
          <div className={"group flex cursor-pointer"}>
            <img className={"opacity-30 group-hover:opacity-100 transition"} src={AnalyticsIcon} alt={"Analytics"}/>
            <h1 className={"opacity-30 group-hover:opacity-100 transition font-medium"}>Analytics</h1>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 20, right: 0, bottom: 0, left: 0,
            }}
          >
            <Tooltip content={<CustomBarTooltip />} />
            <Bar dataKey="Guest" stackId="a" fill="#6ee7b7"  />
            <Bar dataKey="Student" stackId="a" fill="#F27171" />
            <Bar dataKey="Owner" stackId="a" fill="#DAA520" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TrafficStackedBarChart;
