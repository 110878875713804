import React, { useState } from 'react';
import CheckMarkBlack from "../../assets/icons/CheckMarkBlack.svg"

const Dropdown = ({ icon, options, onSelect, functionName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Unselected");

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (option) => {
        setSelectedOption(option.label);
        onSelect(option);
        setIsOpen(false);
    };

    return (
        <div className="z-10 w-full">
            <div className={`border text-sm text-black rounded-md transition p-1.5 flex flex-col ${isOpen ? 'space-y-2' : 'space-y-2'}`}>
                <div className={"flex space-x-2 items-center"}>
                    <img className="h-4 w-4" src={icon} alt="Dropdown"/>
                    <h1 className={"flex-grow h-full text-sm font-medium"}>{functionName}</h1>
                </div>
                <button onClick={toggleDropdown} className={"w-full h-fit bg-slate-100 text-sm text-slate-500 font-medium transition hover:text-slate-800 rounded py-1 px-1.5 flex justify-start"}>
                    <h1 className={""}>{selectedOption}</h1>
                </button>
                {isOpen && (
                    <div className="w-full overflow-y-auto">
                        <ul className="bg-slate-50 border border-gray-200 rounded-md shadow-lg w-full py-2">
                            {options.map((option) => (
                                <li
                                    key={option.value}
                                    className="cursor-pointer px-2 py-1 hover:bg-gray-100 rounded font-medium text-sm text-slate-600 flex justify-between items-center"
                                    onClick={() => handleSelect(option)}
                                >
                                    {option.label}
                                    {option.label === selectedOption && <img className={"w-4 h-4 opacity-50"} src={CheckMarkBlack} alt={"icon"}/>}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dropdown;
