import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig'; // Adjust this import according to your firebase config file's location and export
import { doc, getDoc } from 'firebase/firestore';
import LoadingSpinner from "../common/Loading";

const Plans = ({ orgId }) => {
    const [loading, setLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState('');

    useEffect(() => {
        const fetchPlan = async () => {
            const docRef = doc(db, "organizations", orgId); // Adjust "organizations" to your collection name
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setSelectedPlan(docSnap.data().Plan); // Ensure 'plan' is the correct field in the document
                } else {
                    console.log("No such document!");
                    setSelectedPlan('Free'); // Default to Free if no plan is found
                }
            } catch (error) {
                console.error("Error fetching plan:", error);
            } finally {
                setLoading(false);
            }
        };

        if (orgId) {
            fetchPlan();
        }
    }, [orgId]);

    if (loading) {
        return <div className={"h-full justify-center items-center flex"}>
            <LoadingSpinner />
        </div>;
    }

    return (
        <div className="w-full h-full p-4 flex flex-col bg-slate-50">
            <div className={"h-full w-full flex space-x-3"}>
                <div className={"h-full w-1/3 bg-slate-100 rounded-lg flex flex-col"}>
                    <div className={`w-full h-52 bg-white ${selectedPlan === "Free" ? "border border-blue-400" : ""} rounded-lg shadow-lg flex flex-col p-4`}>
                        <div className={"h-fit w-full flex"}>
                            <div className={"flex-grow h-fit flex flex-col"}>
                                <h1 className={"text-black font-semibold text-lg"}>Free plan</h1>
                                <h1 className={"text-slate-300 font-medium text-sm"}>Best for small teams & individuals</h1>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={"flex-grow w-full flex items-end pb-2"}>
                            <h1 className={"text-4xl text-black font-semibold"}>$0</h1>
                            <h1 className={"pl-0.5 text-sm text-slate-400"}>per month</h1>
                        </div>
                        <div className={"h-fit w-full"}>
                            <div
                                className={"bg-slate-100 rounded-lg w-full h-10 flex items-center justify-center font-medium text-xs hover:bg-slate-200 transition cursor-pointer"}>Get
                                Started
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"h-full w-1/3 bg-slate-100 rounded-lg flex flex-col"}>
                    <div className={"w-full h-52 bg-white rounded-lg shadow-lg flex flex-col p-4"}>
                        <div className={"h-fit w-full flex"}>
                            <div className={"flex-grow h-fit flex flex-col"}>
                                <h1 className={"text-black font-semibold text-lg"}>Max plan</h1>
                                <h1 className={"text-slate-300 font-medium text-sm"}>Best for growing teams.</h1>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={"flex-grow w-full flex items-end pb-2"}>
                            <h1 className={"text-4xl text-black font-semibold"}>$20</h1>
                            <h1 className={"pl-0.5 text-sm text-slate-400"}>per month</h1>
                        </div>
                        <div className={"h-fit w-full"}>
                            <div
                                className={"bg-slate-100 rounded-lg w-full h-10 flex items-center justify-center font-medium text-xs hover:bg-slate-200 transition cursor-pointer"}>Get
                                Started
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"h-full w-1/3 bg-slate-100 rounded-lg flex flex-col"}>
                    <div className={"w-full h-52 bg-white rounded-lg shadow-lg flex flex-col p-4"}>
                        <div className={"h-fit w-full flex"}>
                            <div className={"flex-grow h-fit flex flex-col"}>
                                <h1 className={"text-black font-semibold text-lg"}>Enterprise plan</h1>
                                <h1 className={"text-slate-300 font-medium text-sm"}>Best for large organizations.</h1>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={"flex-grow w-full flex items-end pb-2"}>
                            <h1 className={"text-4xl text-black font-semibold"}>$40</h1>
                            <h1 className={"pl-0.5 text-sm text-slate-400"}>per month</h1>
                        </div>
                        <div className={"h-fit w-full"}>
                            <div className={"bg-slate-100 rounded-lg w-full h-10 flex items-center justify-center font-medium text-xs hover:bg-slate-200 transition cursor-pointer"}>Get Started</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Plans;

