import React, {useEffect} from 'react';
import Footer from "../../components/common/Footer";
import Navigation from "../../components/common/Nav";

export default function Security(){
    return(
        <div className="w-screen h-fit overflow-clip">
          <div className="w-full h-full">
              <Navigation/>
              <Footer/>
          </div>
        </div>
    )
}
