// Search.jsx
import React, { useState } from "react";
import SearchIcon from "../../assets/icons/SearchBlack.svg";

export default function Search({ onSearch }) {
    const [query, setQuery] = useState("");

    const handleInputChange = (e) => {
        const newQuery = e.target.value;
        setQuery(newQuery);
        onSearch(newQuery);
    };

    return (
        <div className={"w-full h-fit rounded-xl items-center flex"}>
            <input
                type="text"
                className={"bg-transparent text-slate-300 text-xl font-medium w-full focus:outline-none"}
                placeholder="Search For Anything"
                value={query}
                onChange={handleInputChange}
            />
        </div>
    );
}
