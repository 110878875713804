import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { fetchOrganizationData } from '../../services/firestoreService';
import SearchContainer from "../layout/SearchComponent";

import HomeIcon from '../../assets/icons/DashboardBlack.svg';
import SitesIcon from '../../assets/icons/SitesBlack.svg';
import AlertsIcon from '../../assets/icons/AlertsBlack.svg';
import MembersIcon from '../../assets/icons/MembersBlack.svg';
import DevicesIcon from '../../assets/icons/DevicesBlack.svg';
import RevenueIcon from '../../assets/icons/MembersBlack.svg';
import FlowsIcon from '../../assets/icons/FlowsIcon.svg'
import UsageIcon from '../../assets/icons/UsageIcon.svg';
import AnalyticsIcon from '../../assets/icons/AnalyticsBlack.svg';
import SettingsIcon from '../../assets/icons/SettingsBlack.svg';
import SupportIcon from '../../assets/icons/SupportBlack.svg';
import UpArrow from '../../assets/icons/UpArrowWhite.svg'
import DownChev from '../../assets/icons/DownChevronBlack.svg'
import SignOutIcon from '../../assets/icons/SignOutBlack.svg'
import PlusIcon from '../../assets/icons/PlusBlack.svg'
import PersonalSettings from "../specific/PersonalSettings";

export default function LeftNav({ onPageChange, FocusedOrg }) {
    const { logout, currentUser } = useAuth();
    const [activeLink, setActiveLink] = useState('Home');
    const [orgLogoUrl, setOrgLogoUrl] = useState('');
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [orgName, setOrgName] = useState('');
    const storage = getStorage();
    const [menuOpen, setMenuOpen] = useState(false);
    const [orgMenuOpen, setOrgMenuOpen] = useState(false);
    const [securityOpen, setSecurityOpen] = useState(true);
    const [paymentsOpen, setPaymentsOpen] = useState(true);
    const [isPersonalSettingsOpen, setIsPersonalSettingsOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const toggleOrgMenu = () => {
        setOrgMenuOpen(!orgMenuOpen);
    };

    const toggleSecurity = () => {
        setSecurityOpen(!securityOpen);
    };
    const togglePayments = () => {
        setPaymentsOpen(!paymentsOpen);
    };

    useEffect(() => {
        const fetchImages = async () => {
            try {
                if (currentUser && currentUser.ProfileURL) {
                    const profilePicRef = ref(storage, currentUser.ProfileURL);
                    const profilePicUrl = await getDownloadURL(profilePicRef);
                    setProfilePicUrl(profilePicUrl);
                } else {
                    console.error('Invalid profile picture URL');
                }

                const orgData = await fetchOrganizationData(FocusedOrg);
                setOrgName(orgData.Name);

                if (orgData.Logo) {
                    const orgLogoRef = ref(storage, orgData.Logo);
                    const orgLogoUrl = await getDownloadURL(orgLogoRef);
                    setOrgLogoUrl(orgLogoUrl);
                } else {
                    console.error('Invalid organization logo path');
                }
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [currentUser]);

    const handleLinkClick = (link) => {
        setActiveLink(link);
        onPageChange(link);
    };

    const linkClasses = (link) => {
        return `w-full h-8 text-sm transition rounded flex space-x-1 items-center font-medium cursor-pointer pl-2 ${
            activeLink === link ? 'bg-slate-100 text-slate-700' : 'hover:text-slate-700 text-slate-500'
        }`;
    };

    return (
        <div className="flex flex-col w-56 h-full border-r-slate-100 border-r space-y-4 px-2 py-2 relative">
            <div className="w-full h-fit flex justify-between">
                <PersonalSettings isOpen={isPersonalSettingsOpen} onClose={() => setIsPersonalSettingsOpen(false)} />
                <div
                    className={`w-fit h-7 flex items-center justify-start space-x-1 px-2 my-1.5 hover:bg-slate-100 ${orgMenuOpen ? "rounded-t-lg bg-slate-100" : "rounded-lg bg-white"}  relative cursor-pointer transition`}
                    onClick={toggleOrgMenu}>
                    {orgLogoUrl ? (
                        <img src={orgLogoUrl} alt="Org Logo" className="rounded-md h-5 w-5"/>
                    ) : (
                        <div className="bg-slate-200 rounded-md h-5 w-5"></div>
                    )}
                    <div
                        className="font-semibold text-slate-900 text-sm w-28 whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {orgName || "Loading..."}
                    </div>
                    <img className={"h-5 w-5 opacity-50 scale-75"} src={DownChev}/>
                    {orgMenuOpen && (
                        <div
                            className="absolute top-7 -left-1 w-full bg-slate-100 shadow-xl rounded-b-lg z-10 overflow-clip">
                            <button
                                className="group flex items-center space-x-1 w-full text-left px-2 py-1 text-gray-700 hover:bg-slate-200 transition">
                                <img src={PlusIcon} className={"h-5 w-5 opacity-30 group-hover:opacity-75 transition"}/>
                                <h1 className={"text-sm font-medium text-slate-400 group-hover:text-slate-600 transition"}>New
                                    Organization</h1>
                            </button>
                        </div>
                    )}
                </div>
                <div className="relative flex-grow h-10 flex justify-end items-center mr-1 space-x-1">
                    {profilePicUrl ? (
                        <button onClick={toggleMenu}>
                            <img src={profilePicUrl} alt="Profile Pic"
                                 className="rounded-full h-8 w-8 p-1 hover:bg-slate-100 transition"/>
                        </button>
                    ) : (
                        <button onClick={toggleMenu}>
                            <div className="bg-slate-200 rounded-full h-8 w-8"></div>
                        </button>
                    )}
                    {menuOpen && (
                        <div
                            className="absolute top-10 -right-1 w-52 bg-white shadow-xl border rounded-lg z-10 overflow-clip">
                            <button onClick={() => setIsPersonalSettingsOpen(true)}
                                    className="group flex items-center space-x-2 w-full text-left px-4 py-2 text-gray-700 hover:bg-slate-50 transition">
                                <img src={SettingsIcon} className={"opacity-30 group-hover:opacity-75 transition"}/>
                                <h1 className={"text-sm font-medium text-slate-400 group-hover:text-slate-600 transition"}>Settings</h1>
                            </button>
                            <button onClick={logout}
                                    className="group flex items-center space-x-2 w-full text-left px-4 py-2 text-gray-700 hover:bg-slate-50 transition">
                                <img src={SignOutIcon} className={"opacity-30 group-hover:opacity-75 transition"}/>
                                <h1 className={"text-sm font-medium text-slate-400 group-hover:text-slate-600 transition"}>Log
                                    Out</h1>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <SearchContainer/>
            <div className="w-full h-fit flex flex-col">
                <div className={linkClasses('Home')} onClick={() => handleLinkClick('Home')}>
                    <img className="h-5 w-5 opacity-70 mt-0.5" src={HomeIcon} alt="Home"/>
                    <h1>Home</h1>
                </div>
                <div className={linkClasses('Sites')} onClick={() => handleLinkClick('Sites')}>
                    <img className="h-5 w-5 opacity-70 mt-0.5" src={SitesIcon} alt="Sites"/>
                    <h1>Sites</h1>
                </div>
                <div className={linkClasses('Team')} onClick={() => handleLinkClick('Team')}>
                    <img className="h-5 w-5 opacity-70 mt-0.5" src={MembersIcon} alt="Team"/>
                    <h1>Team</h1>
                </div>
            </div>
            {/* Security Section with Collapsible Content */}
            <div className="w-full h-fit flex flex-col">
                <div className="font-medium text-slate-400 text-sm pl-2.5 cursor-pointer" onClick={toggleSecurity}>

                    Security
                    <img src={DownChev}
                                  className={`${securityOpen ? "" : "-rotate-90"} opacity-30 inline-block h-3.5 w-3.5 transition`}/>
                </div>
                {securityOpen && (
                    <div className={"w-full h-fit flex space-x-1 pl-3"}>
                        <div className={"h-full w-0.5 rounded-full bg-slate-100"}></div>
                        <ul className={"flex-grow h-fit"}>
                            <li className={linkClasses('SecurityHardware')} onClick={() => handleLinkClick('SecurityHardware')}>
                                <img className="h-5 w-5 opacity-70 mt-0.5" src={DevicesIcon} alt="Locks"/>
                                <h1>Devices</h1>
                            </li>
                            <li className={linkClasses('Flows')} onClick={() => handleLinkClick('Flows')}>
                                <img className="h-5 w-5 opacity-70 mt-0.5" src={FlowsIcon} alt="Flows"/>
                                <h1>Flows</h1>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            {/* Payments Section with Collapsible Content */}
            <div className="w-full h-fit flex flex-col">
                <div className="font-medium text-slate-400 text-sm pl-2.5 cursor-pointer items-center"
                     onClick={togglePayments}>
                    Payments <img src={DownChev} className={`${paymentsOpen ? "" : "-rotate-90"} opacity-30 inline-block h-3.5 w-3.5 transition`}/>
                </div>
                {paymentsOpen && (
                    <div className={"w-full h-fit flex space-x-1 pl-3"}>
                        <div className={"h-full w-0.5 rounded-full bg-slate-100"}></div>
                        <ul className={"flex-grow h-fit"}>
                            <li className={linkClasses('PaymentHardware')}
                                onClick={() => handleLinkClick('PaymentHardware')}>
                                <img className="h-5 w-5 opacity-70 mt-0.5" src={DevicesIcon} alt="Locks"/>
                                <h1>Devices</h1>
                            </li>
                            <li className={linkClasses('Analytics')} onClick={() => handleLinkClick('Analytics')}>
                                <img className="h-5 w-5 opacity-70 mt-0.5" src={AnalyticsIcon} alt="Analytics"/>
                                <h1>Analytics</h1>
                            </li>
                        </ul>
                    </div>
    )
}
</div>
    <div className="flex-grow w-full"></div>
    <div className="w-full h-fit flex flex-col">
        <ul>
            <li className={linkClasses('Settings')} onClick={() => handleLinkClick('Settings')}>
                <img className="h-5 w-5 opacity-70 mt-0.5" src={SettingsIcon} alt="Settings"/>
                <h1>Settings</h1>
            </li>
            <li className={linkClasses('Support')} onClick={() => handleLinkClick('Support')}>
                <img className="h-5 w-5 opacity-70 mt-0.5" src={SupportIcon} alt="Support"/>
                <h1>Support</h1>
            </li>
        </ul>
    </div>
    <div className={"px-1"}>
        <div
            className={"border rounded-full px-1 text-xs py-1 font-medium flex items-center space-x-1 hover:border-slate-500 transition cursor-pointer"}>
                    <img className="h-5 w-5 bg-black rounded-full opacity-70 mr-0.5" src={UpArrow} alt="Support"/>
                    <h1>Upgrade to Mooslix Max</h1>
                </div>
            </div>
        </div>
    );
}
