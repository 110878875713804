// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import {getStorage} from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_GbZvvnv-YoI9DrP6AqU1NZSi37cKIdk",
  authDomain: "mooslix.firebaseapp.com",
  databaseURL: "https://mooslix-default-rtdb.firebaseio.com",
  projectId: "mooslix",
  storageBucket: "mooslix.appspot.com",
  messagingSenderId: "946627642070",
  appId: "1:946627642070:web:5727777a814bec38cfbf77",
  measurementId: "G-LXKR6E7HJD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);

const storage = getStorage(app)

export { auth, db, storage };
