import React, { useState, useEffect } from 'react';
import MooslixNavLogo from '../../assets/images/MooslixLogoBlack.svg';
import MooslixNavLogoText from '../../assets/images/MooslixLogoTextBlack.svg';
import {IoClose, IoMenu} from "react-icons/io5";
import SecurityHardware from "../../assets/images/ReaderImage.png"
import RightChevronBlack from "../../assets/icons/RightChevronBlack.svg"

export default function Navigation() {
    const [liOptions] = useState(["Platform", "Hardware" ]);
    const [optionLinks] = useState(["/platform", "/payments", ])
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [showNav, setShowNav] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showUl, setShowUl] = useState(!isMobile);


    useEffect(() => {
        const handleScroll = () => {
            let scrollTop = window.scrollY;
            if (!isMobile){
                if (scrollTop > lastScrollTop) {
                    // Scrolling down
                    setShowNav(false);
                    setShowUl(false);
                } else {
                    // Scrolling up
                    setShowNav(true);
                    setShowUl(true);
                }
            }

            setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // For Mobile or negative scrolling
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setShowUl(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [lastScrollTop]);

    const toggleMenu = () => {
        setShowUl(!showUl);
    };

    return (
        <div className={`w-full h-fit justify-between flex left-0 top-0 fixed transition-all duration-300 ease-in-out z-50 ${showNav ? 'bg-white bg-opacity-80 backdrop-blur-lg' : ''}`}>
            {!isMobile ? (
                <div className={"w-full h-fit justify-between flex"}>
                    <div className={"flex-grow h-fit flex items-center"}>
                        <a className={"items-center justify-center logo flex scale-75 ml-2"} href={"/"}>
                            <img className={""} src={MooslixNavLogo} alt={"Logo"}/>
                            <img className={""} src={MooslixNavLogoText} alt={"Mooslix"}/>
                        </a>
                    </div>
                    <ul id="products-list"
                        className={`items-center w-fit justify-center flex space-x-8 transition-all duration-300 ease-in-out ${showUl ? 'flex' : 'hidden'}`}>
                        {liOptions.map((option, index) => (
                            <li key={index}
                                className="content-center font-medium text-black text-sm hover:opacity-75 transition duration-300 ease-in-out flex space-x-1">
                                <a href={optionLinks[liOptions.indexOf(option)]}>{option}</a>
                                <h1 className={"text-2xs bg-slate-200 rounded px-1 text-slate-500"}>BETA</h1>
                            </li>
                        ))}
                    </ul>
                    <div className={"flex-grow justify-end flex space-x-6 mr-12"}>
                        <a href="/login" className={"flex"}>
                            <button>
                                <div
                                    className="content-center font-medium text-black text-sm hover:bg-opacity-5 transition-transform duration-300 ease-in-out">Login
                                </div>
                            </button>
                        </a>
                        <a href="/signup" className={"flex"}>
                            <button>
                                <div
                                    className="p-2 rounded-lg bg-black content-center font-medium text-white text-sm hover:bg-black hover:bg-opacity-80 transition ease-in-out">Get
                                    Started
                                </div>
                            </button>
                        </a>
                    </div>
                </div>
            ) : (
                <div className={"w-full h-fit justify-between flex bg-white shadow fixed"}>
                    <div className={"flex-grow h-fit flex items-center"}>
                        <a className={"items-center justify-center logo flex scale-75 ml-2"} href={"/"}>
                            <img className={""} src={MooslixNavLogo} alt={"Logo"}/>
                            <img className={""} src={MooslixNavLogoText} alt={"Mooslix"}/>
                        </a>
                    </div>
                    <button onClick={toggleMenu} className="p-2 mr-8 focus:outline-none z-20">
                        {showUl ? <IoClose size={24}/> : <IoMenu size={24}/>}
                    </button>
                    {isMobile && showUl && (
                        <ul className="absolute top-1.5 left-0 w-full h-screen mt-16 bg-white text-white flex flex-col items-center space-y-4 py-4 px-12 z-10">
                            {liOptions.map((option, index) => (
                                <li key={index}
                                    className="flex w-full h-fit font-semibold text-black text-2xl hover:opacity-75 transition duration-300 ease-in-out items-center space-x-2">
                                    <a className={"w-fit h-fit flex items-center space-x-2"}
                                       href={optionLinks[liOptions.indexOf(option)]}>
                                        <h1>{option}</h1>
                                        <img src={RightChevronBlack} className={"mt-0.5"}/>
                                    </a>
                                </li>
                            ))}
                            <li className="flex w-full h-fit font-semibold text-black text-2xl hover:opacity-75 transition duration-300 ease-in-out items-center space-x-2">
                                <a className={"w-fit h-fit flex items-center space-x-2"}
                                   href={"/login"}>
                                    <h1>Login</h1>
                                    <img src={RightChevronBlack} className={"mt-0.5"}/>
                                </a>
                            </li>
                            <li className="flex w-full h-fit font-semibold text-black text-2xl hover:opacity-75 transition duration-300 ease-in-out items-center space-x-2">
                                <a className={"w-fit h-fit flex items-center space-x-2"}
                                   href={"/signup"}>
                                    <h1>Sign Up</h1>
                                    <img src={RightChevronBlack} className={"mt-0.5"}/>
                                </a>
                            </li>
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
}

