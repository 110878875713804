import React from "react";
import ReaderImage from "../../assets/images/ReaderImage.png";
import StandImage from "../../assets/images/Stand.png"
import MiddleburyLogo from "../../assets/images/MiddleburySeal220.gif";
import GlassCageMobile from "../../assets/images/GlassCage.png";
import PlatformImage from "../../assets/images/PlatformImage.png"
import Plus from "../../assets/icons/PlusBlack.svg"

export default function LandingBody() {
    return (
        <div className="flex flex-col items-center w-full h-fit px-4 md:px-16 lg:px-32 xl:px-64 py-6">
            <div className="h-fit w-full items-center flex flex-col pt-52 pb-16 md:py-40 lg:py-60">
                <h1 className="opacity-50 text-black text-lg md:text-xl text-center">
                    Securing the worlds leading organizations
                </h1>
                <div className="w-full flex h-20 justify-center mt-8">
                    <img className="h-full aspect-square" src={MiddleburyLogo} alt="Middlebury Logo" />
                </div>
            </div>
            <div className={"h-fit w-full flex flex-col"}>
                <div className={"w-full h-fit flex flex-col lg:flex-row px-4"}>
                    <h1 className={"w-1/2 text-6xl font-semibold"}>Made for today's trailblazing teams</h1>
                    <h2 className={"w-1/2 text-lg text-slate-300 py-3 lg:pl-16 lg:pr-8 font-medium"}>
                        Mooslix elevates your team's authentication with cardless access control and payment processing, steamlined by one comprehensive web platform.
                    </h2>
                </div>
                <div className={"px-4 flex flex-col lg:flex-row w-full space-y-4 lg:space-y-0 space-x-0 lg:space-x-4 mt-16"}>
                    <div className={"w-80 aspect-square bg-slate-100 rounded-xl relative flex items-end justify-between text-xl text-black"}>
                        <div className={"mb-4 ml-4 font-medium"}>Flexible Control</div>
                        <div
                            className={"group h-9 w-9 mb-4 mr-4 rounded-full border-2 border-slate-200 hover:bg-slate-200 hover:bg-opacity-50 border-opacity-50 p-2 transition cursor-pointer"}>
                            <img className={"group-hover:opacity-75 opacity-50 transition"} src={Plus}/>
                        </div>
                    </div>
                    <div className={"w-80 aspect-square bg-slate-100 rounded-xl relative flex items-end justify-between text-xl text-black"}>
                        <div className={"mb-4 ml-4 font-medium"}>Endless Analytics</div>
                        <div
                            className={"group h-9 w-9 mb-4 mr-4 rounded-full border-2 border-slate-200 hover:bg-slate-200 hover:bg-opacity-50 border-opacity-50 p-2 transition cursor-pointer"}>
                            <img className={"group-hover:opacity-75 opacity-50 transition"} src={Plus}/>
                        </div>
                    </div>
                    <div className={"w-80 aspect-square bg-slate-100 rounded-xl relative flex items-end justify-between text-xl text-black"}>
                        <div className={"mb-4 ml-4 font-medium"}>Empowering Integrations</div>
                        <div
                            className={"group h-9 w-9 mb-4 mr-4 rounded-full border-2 border-slate-200 hover:bg-slate-200 hover:bg-opacity-50 border-opacity-50 p-2 transition cursor-pointer"}>
                            <img className={"group-hover:opacity-75 opacity-50 transition"} src={Plus}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-screen w-full flex flex-col-reverse md:flex-row mt-48 md:mt-0">
                <div className="w-full md:w-1/2 h-full flex flex-col justify-between py-12 md:py-36 pr-0 md:pr-16">
                    <div>
                        <h1 className="text-3xl md:text-5xl font-medium">Magnifier 1.0</h1>
                        <h1 className="text-xl md:text-2xl opacity-75 mt-2">Access Control</h1>
                        <p className="mt-6 opacity-50">
                            Mooslix revolutionizes access control with cutting-edge biometric verification technology. Offering unmatched accuracy and speed, Mooslix ensures secure and seamless access management. Experience the future of security with Mooslix, where reliability meets innovation.
                        </p>
                    </div>
                    <div className="px-3 py-2.5 mt-5 md:mt-0 lg:mt-0 rounded-lg text-white bg-black w-fit cursor-pointer hover:opacity-75">
                        <a href="/security">Learn More</a>
                    </div>
                </div>
                <div className="w-full md:w-1/2 h-full flex items-center justify-center">
                    <img className="w-full object-cover" src={ReaderImage} alt="Reader" />
                </div>
            </div>
            <div className="h-screen w-full flex flex-col md:flex-row mt-16">
                <div className="w-full md:w-1/2 h-full flex items-center justify-center">
                    <img className="w-full object-cover" src={StandImage} alt="Reader" />
                </div>
                <div className="w-full md:w-1/2 h-full flex flex-col justify-between py-12 md:py-36 pl-0 md:pl-16">
                    <div>
                        <h1 className="text-3xl md:text-5xl font-medium">Terminal 1.0</h1>
                        <h1 className="text-xl md:text-2xl opacity-75 mt-2">Payment Processing</h1>
                        <p className="mt-6 opacity-50">
                            Mooslix elevates payment processing with its advanced biometric authentication technology. Ensuring both speed and security, Mooslix offers a seamless transaction experience that eliminates the need for traditional payment methods. Embrace the future of payments with Mooslix, where convenience and security converge to transform your payment processes.
                        </p>
                    </div>
                    <div className="px-3 py-2.5 mt-5 rounded-lg text-white bg-black w-fit cursor-pointer hover:opacity-75">
                        <a href="/payments">Discover</a>
                    </div>
                </div>
            </div>
            <div className="h-screen w-full flex flex-col-reverse md:flex-row mt-48 md:mt-16">
                <div className="w-full md:w-1/2 h-full flex flex-col justify-between py-12 md:py-36 pr-0 md:pr-16">
                    <div>
                        <h1 className="text-3xl md:text-5xl font-medium">GlassCage Platform</h1>
                        <h1 className="text-xl md:text-2xl opacity-75 mt-2">Web Application</h1>
                        <p className="mt-6 opacity-50">
                            Mooslix revolutionizes access control with cutting-edge biometric verification technology. Offering unmatched accuracy and speed, Mooslix ensures secure and seamless access management. Experience the future of security with Mooslix, where reliability meets innovation.
                        </p>
                    </div>
                    <div className="px-3 py-2.5 mt-5 rounded-lg text-white bg-black w-fit cursor-pointer hover:opacity-75">
                        <a href="/signup">Get Started</a>
                    </div>
                </div>
                <div className="w-full md:w-1/2 h-full flex items-center justify-center">
                    <img className="w-full object-cover scale-150" src={PlatformImage} alt="Reader" />
                </div>
            </div>
            <div className="h-screen w-full flex flex-col md:flex-row mt-16">
                <div className="w-full md:w-1/2 h-full flex items-center justify-center">
                    <img className="w-full object-contain scale-110" src={GlassCageMobile} alt="GlassCage Mobile" />
                </div>
                <div className="w-full md:w-1/2 h-full flex flex-col justify-between py-12 md:py-36 pl-0 md:pl-16">
                    <div>
                        <h1 className="text-3xl md:text-5xl font-medium">GlassCage Mobile</h1>
                        <h1 className="text-xl md:text-2xl opacity-75 mt-2">Native App</h1>
                        <p className="mt-6 opacity-50">
                            Mooslix elevates payment processing with its advanced biometric authentication technology. Ensuring both speed and security, Mooslix offers a seamless transaction experience that eliminates the need for traditional payment methods. Embrace the future of payments with Mooslix, where convenience and security converge to transform your payment processes.
                        </p>
                    </div>
                    <div className="px-3 py-2.5 mt-5 rounded-lg text-white bg-black w-fit cursor-not-allowed opacity-75">
                        Coming Soon
                    </div>
                </div>
            </div>
        </div>
    );
}
