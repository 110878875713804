import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebaseConfig'; // Adjust the path as necessary
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable, getStorage } from 'firebase/storage';

const PersonalSettings = ({ isOpen, onClose }) => {
    const { currentUser } = useAuth();
    const storage = getStorage();
    const [userData, setUserData] = useState({
        Email: '',
        PhoneNumber: '',
        FirstName: '',
        LastName: '',
        ProfileURL: ''
    });
    const [loading, setLoading] = useState(true);
    const [profilePicFile, setProfilePicFile] = useState(null);
    const [profilePicUrl, setProfilePicUrl] = useState('');

    // Fetch user data from Firestore
    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                const userDocRef = doc(db, 'user_data', currentUser.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setUserData(data);

                    // Fetch the profile picture URL from storage
                    if (data.ProfileURL) {
                        try {
                            const profilePicRef = ref(storage, data.ProfileURL);
                            const url = await getDownloadURL(profilePicRef);
                            setProfilePicUrl(url);
                        } catch (error) {
                            console.error('Error fetching profile picture URL:', error);
                        }
                    }
                }
            }
            setLoading(false);
        };

        fetchUserData();
    }, [currentUser]);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({ ...prevState, [name]: value }));
    };

    // Handle profile picture file change
    const handleProfilePicChange = (e) => {
        if (e.target.files[0]) {
            setProfilePicFile(e.target.files[0]);
        }
    };

    // Save user data and upload new profile picture if selected
    const handleSave = async () => {
        try {
            const userDocRef = doc(db, 'user_data', currentUser.uid);
            await updateDoc(userDocRef, {
                Email: userData.Email,
                PhoneNumber: userData.PhoneNumber,
                FirstName: userData.FirstName,
                LastName: userData.LastName,
            });

            if (profilePicFile) {
                const profilePicRef = ref(storage, `profile_pictures/${currentUser.uid}`);
                const uploadTask = uploadBytesResumable(profilePicRef, profilePicFile);

                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        // You can add progress tracking here if desired
                    },
                    error => {
                        console.error('Error uploading profile picture:', error);
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        await updateDoc(userDocRef, { ProfileURL: downloadURL });
                        setProfilePicUrl(downloadURL);
                    }
                );
            }

            alert('Settings saved successfully');
            onClose(); // Close the modal after saving
        } catch (error) {
            console.error('Error saving settings:', error);
            alert('Failed to save settings');
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 h-screen w-screen bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white w-2/3 h-2/3 p-6 rounded-lg shadow-lg relative">
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <h1 className="text-slate-400 font-medium text-sm mb-2">Personal Settings</h1>
                        <div className="flex space-x-4">
                            <div className="w-48 h-fit flex flex-col space-y-4">
                                {profilePicUrl ? (
                                    <img
                                        src={profilePicUrl}
                                        alt="Profile"
                                        className="w-full aspect-square rounded-lg"
                                    />
                                ) : (
                                    <div className="w-24 h-24 rounded-lg bg-slate-100"></div>
                                )}
                                <div className="flex-grow h-24 rounded-lg border-slate-100 border-2 border-dashed">
                                    <input
                                        type="file"
                                        name="ProfilePicture"
                                        onChange={handleProfilePicChange}
                                        className="mt-1"
                                    />
                                </div>
                            </div>
                            <div className={"flex flex-col flex-grow h-full"}>
                                <div className="flex space-x-3">
                                    <input
                                        type="text"
                                        name="FirstName"
                                        value={userData.FirstName}
                                        onChange={handleInputChange}
                                        className="mt-1 p-2 border border-gray-300 rounded w-full"
                                    />
                                    <input
                                        type="text"
                                        name="LastName"
                                        value={userData.LastName}
                                        onChange={handleInputChange}
                                        className="mt-1 p-2 border border-gray-300 rounded w-full"
                                    />
                                </div>
                                <div className="">
                                    <input
                                        type="email"
                                        name="Email"
                                        value={userData.Email}
                                        onChange={handleInputChange}
                                        className="mt-1 p-2 border border-gray-300 rounded w-full"
                                    />
                                </div>
                                <div className="">
                                    <input
                                        type="text"
                                        name="PhoneNumber"
                                        value={userData.PhoneNumber}
                                        onChange={handleInputChange}
                                        className="mt-1 p-2 border border-gray-300 rounded w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={handleSave}
                            className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                        >
                            Save Settings
                        </button>
                        <button
                            onClick={onClose}
                            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                        >
                            &times;
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default PersonalSettings;


