// searchService.js
import { db } from '../firebaseConfig'; // Adjust the path as necessary
import { collection, query, where, getDocs } from 'firebase/firestore';

export const searchAllCollections = async (queryText) => {
    const results = [];

    // Search in pages collection
    const pagesQuery = query(collection(db, 'pages'), where('name', '>=', queryText), where('name', '<=', queryText + '\uf8ff'));
    const pagesSnapshot = await getDocs(pagesQuery);
    pagesSnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data(), type: 'Pages' });
    });

    // Search in members collection
    const membersQuery = query(collection(db, 'user_data'), where('FirstName', '>=', queryText), where('FirstName', '<=', queryText + '\uf8ff'));
    const membersSnapshot = await getDocs(membersQuery);
    membersSnapshot.forEach((doc) => {
        const data = doc.data();
        results.push({ id: doc.id, ...data, Name: `${data.FirstName} ${data.LastName}`, type: 'Member' });
    });

    // Search in devices collection
    const devicesQuery = query(collection(db, 'hardware'), where('Name', '>=', queryText), where('Name', '<=', queryText + '\uf8ff'));
    const devicesSnapshot = await getDocs(devicesQuery);
    devicesSnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data(), type: 'Devices' });
    });

    // Search in sites collection
    const sitesQuery = query(collection(db, 'sites'), where('Name', '>=', queryText), where('Name', '<=', queryText + '\uf8ff'));
    const sitesSnapshot = await getDocs(sitesQuery);
    sitesSnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data(), type: 'Sites' });
    });

    return results;
};
