import React, { useState, useEffect } from "react";
import Search from "../common/Search";
import { searchAllCollections } from "../../services/searchService"; // Adjust the path as necessary
import SearchIcon from "../../assets/icons/SearchBlack.svg";

export default function SearchContainer() {
    const [results, setResults] = useState([]);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const handleSearch = async (query) => {
        if (query.length === 0) {
            setResults([]);
            return;
        }

        const searchResults = await searchAllCollections(query);
        setResults(searchResults);
        console.log(query.length + " | " + searchResults);
    };

    const toggleOverlay = () => {
        setIsOverlayOpen(!isOverlayOpen);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            const isCmdOrCtrl = event.metaKey || event.ctrlKey;
            const isKKey = event.key === 'k';

            if (isCmdOrCtrl && isKKey) {
                event.preventDefault();
                setIsOverlayOpen(true);
            }
        };

        // Add the keydown event listener
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <button
                onClick={toggleOverlay}
                className="group px-2 py-1 w-full items-center space-x-1 bg-slate-50 hover:bg-slate-100 flex justify-between rounded-lg transition text-slate-500 hover:text-slate-800"
            >
                <div className={"flex space-x-1"}>
                    <img className={"w-5 h-5 opacity-75 group-hover:opacity-90 transition"} src={SearchIcon} alt="Search"/>
                    <h1 className={"font-medium pr-1 text-sm"}>Search</h1>
                </div>
                <div className={"flex space-x-0.5"}>
                    <h1 className={"bg-slate-200 rounded px-2 py-0.5 font-medium text-sm border-b border-b-slate-300"}>⌘</h1>
                    <h1 className={"bg-slate-200 rounded px-2 py-0.5 font-medium text-sm border-b border-b-slate-300"}>K</h1>
                </div>
            </button>

            {isOverlayOpen && (
                <div
                    className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-50"
                    onClick={toggleOverlay}
                >
                    <div
                        className="bg-white p-6 rounded-t-xl w-full max-w-lg"
                        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the overlay
                    >
                        <Search onSearch={handleSearch}/>
                        <div className={"mt-0 relative"}>
                            {results.length === 0 ? (
                                <></>
                            ) : (
                                <ul className="space-y-2 absolute bg-white shadow h-fit w-full mt-4">
                                    {results.map((result) => (
                                        <li key={result.id} className="p-2 rounded flex space-x-1.5 cursor-pointer">
                                            <h1 className={"font-bold"}>{result.Name}</h1>
                                            <h1 className={"opacity-40 font-medium"}>• {result.type}</h1>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className={"bg-slate-100 w-full max-w-lg h-fit rounded-b-xl flex justify-center"}>
                        <h1 className={"text-sm font-medium text-slate-600 py-4 px-6"}>
                            Press {}
                            <span className={"bg-white border-b-2 border-slate-400 px-1.5 py-0.5 rounded shadow"}>⌘</span>
                            <span className={"bg-white border-b-2 border-slate-400 px-1.5 py-0.5 rounded shadow ml-1"}>K</span>
                            {} on your keyboard to search from anywhere
                        </h1>
                    </div>
                </div>
            )}
        </>
    );
}
