import React, { useEffect, useState } from 'react';
import {
    fetchOrganizationData,
    fetchSites,
    addHardware,
    fetchPaymentHardwareBySiteId,
    checkIfUserIsGuest
} from '../../services/firestoreService';
import Plus from "../../assets/icons/PlusBlack.svg";
import MenuIcon from "../../assets/icons/ConfigureIconBlack.svg";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { ReactComponent as HighBattery } from "../../assets/icons/HighBattery.svg";
import { ReactComponent as MediumBattery } from "../../assets/icons/MedBattery.svg";
import { ReactComponent as LowBattery } from "../../assets/icons/LowBattery.svg";
import { ReactComponent as OwnerAccess } from "../../assets/icons/OwnerIcon.svg";
import { ReactComponent as StudentAccess } from "../../assets/icons/StudentIcon.svg";
import { ReactComponent as PublicAccess } from "../../assets/icons/PublicIcon.svg";
import GroupIcon from "../../assets/icons/GroupIcon.svg";
import FilterIcon from "../../assets/icons/ConfigureIconBlack.svg";
import SortIcon from "../../assets/icons/SortBlack.svg";
import Dropdown from '../common/Dropdown';
import Details from '../../assets/icons/DetailsBlack.svg';
import Remove from '../../assets/icons/RemoveRed.svg';
import LoadingSpinner from "../common/Loading";

const PaymentHardwareTable = ({ orgId }) => {
    const [hardware, setHardware] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'siteName', direction: 'ascending' });
    const [groupBy, setGroupBy] = useState(null);
    const [filterBy, setFilterBy] = useState(null);
    const [orgDetails, setOrgDetails] = useState(null);
    const [orgLogoUrl, setOrgLogoUrl] = useState('');
    const [showAddHardware, setShowAddHardware] = useState(false);
    const [newHardwareName, setNewHardwareName] = useState('');
    const [newHardwareType, setNewHardwareType] = useState('');
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [selectedHardware, setSelectedHardware] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const storage = getStorage();
    const [userIsGuest, setUserIsGuest] = useState(false);

    useEffect(() => {
        const checkUserStatus = async () => {
            const isGuest = await checkIfUserIsGuest(orgId);
            setUserIsGuest(isGuest);
        };

        const getOrgDetails = async () => {
            try {
                const details = await fetchOrganizationData(orgId);
                setOrgDetails(details);

                if (details.Logo) {
                    const orgLogoRef = ref(storage, details.Logo);
                    const orgLogoUrl = await getDownloadURL(orgLogoRef);
                    setOrgLogoUrl(orgLogoUrl);
                }
            } catch (error) {
                console.error('Error fetching organization details:', error);
            }
        };

        const getHardware = async () => {
            try {
                const sites = await fetchSites(orgId);
                const hardwarePromises = sites.map(async (site) => {
                    const hardware = await fetchPaymentHardwareBySiteId(orgId, site.id);
                    return hardware.map(h => ({ ...h, siteName: site.Name }));
                });
                const hardwareData = await Promise.all(hardwarePromises);
                setHardware(hardwareData.flat());
            } catch (error) {
                console.error('Error fetching hardware:', error);
            } finally {
                setLoading(false);
            }
        };

        checkUserStatus();
        getOrgDetails();
        getHardware();
    }, [orgId]);

    const handleSort = (option) => {
        const key = option.value;
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleGroupBy = (option) => {
        setGroupBy(option.value);
    };

    const handleFilterBy = (option) => {
        setFilterBy(option.value);
    };

    const getBatteryLevelIcon = (BatteryLevel) => {
        if (BatteryLevel > 75) {
            return <HighBattery />;
        } else if (BatteryLevel >= 25 && BatteryLevel <= 75) {
            return <MediumBattery />;
        } else {
            return <LowBattery />;
        }
    };

    const getAccessLevelIcon = (AccessLevel) => {
        if (AccessLevel === 'Primary') {
            return <OwnerAccess />;
        } else if (AccessLevel === 'Secondary') {
            return <StudentAccess />;
        } else {
            return <PublicAccess />;
        }
    };

    const handleContextMenu = (e, hardware) => {
        e.preventDefault();
        const rect = e.currentTarget.getBoundingClientRect();
        setSelectedHardware(hardware);
        setContextMenuPosition({ x: rect.right, y: rect.bottom });
        setContextMenuVisible(prev => (prev && selectedHardware === hardware ? false : true));
    };

    const handleRemoveHardware = async (hardwareId) => {
        // Implement hardware removal logic here
        alert(`Remove hardware with ID: ${hardwareId}`);
        setContextMenuVisible(false);
    };

    const handleAddHardware = async () => {
        try {
            await addHardware(orgId, { Name: newHardwareName, Type: newHardwareType });
            setNewHardwareName('');
            setNewHardwareType('');
            setShowAddHardware(false);
            const sites = await fetchSites(orgId);
            const hardwarePromises = sites.map(async (site) => {
                const hardware = await fetchPaymentHardwareBySiteId(orgId, site.id);
                return hardware.map(h => ({ ...h, siteName: site.Name }));
            });
            const hardwareData = await Promise.all(hardwarePromises);
            setHardware(hardwareData.flat());
        } catch (error) {
            console.error('Error adding hardware:', error);
        }
    };

    const filteredHardware = filterBy
        ? hardware.filter(item => item[filterBy] === filterBy)
        : hardware;

    const sortedHardware = filteredHardware.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const groupedHardware = groupBy
        ? sortedHardware.reduce((acc, item) => {
            const key = item[groupBy];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {})
        : { 'All Devices': sortedHardware };

    const renderLockStatus = (openTime, closeTime, currentTime = new Date()) => {
        if (!openTime || !closeTime) {
            console.error('OpenTime or CloseTime is undefined');
            return (
                <div className={"w-full space-x-2 pl-2 h-fit flex items-center"}>
                    <div className={"w-1.5 h-1.5 bg-slate-700 rounded-full"}></div>
                    <div className={"text-sm font-medium text-slate-700"}>N/A</div>
                </div>
            );
        }

        const [openHour, openMinute] = openTime.split(':').map(Number);
        const [closeHour, closeMinute] = closeTime.split(':').map(Number);

        const openDate = new Date(currentTime);
        openDate.setHours(openHour, openMinute, 0, 0);

        const closeDate = new Date(currentTime);
        closeDate.setHours(closeHour, closeMinute, 0, 0);

        const isOpen = currentTime >= openDate && currentTime < closeDate;

        return (
            <div className={"w-full space-x-2 pl-2 h-fit flex items-center"}>
                <div className="relative flex items-center justify-center">
                    <div className={`w-1.5 h-1.5 ${isOpen ? 'bg-green-500' : 'bg-slate-700'} rounded-full`}></div>
                    {isOpen && (
                        <div className="absolute w-2 h-2 rounded-full bg-green-500 opacity-30 animate-ping"></div>
                    )}
                </div>
                <div className={`text-sm font-medium ${isOpen ? 'text-green-500' : 'text-slate-700'}`}>
                    {isOpen ? 'Open' : 'Locked'}
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div className={"h-full justify-center items-center flex"}>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="py-3 bg-white min-h-screen relative">
            <div className="px-6 flex justify-between items-center mb-2">
                <div className="flex items-center space-x-0.5">
                    <div className="py-0.5 px-1 flex hover:bg-slate-100 transition rounded cursor-pointer items-center">
                        <h1 className="text-sm font-semibold text-slate-700">Payments</h1>
                    </div>
                    <h1 className={"font-bold text-slate-300"}>•</h1>
                    <div className="py-0.5 px-1 flex hover:bg-slate-100 transition rounded cursor-pointer items-center">
                        <h1 className="text-sm font-semibold text-slate-700">Devices</h1>
                    </div>
                </div>
                <div className="flex items-center space-x-4 h-full">
                    <span className="text-gray-400 text-sm">{hardware.length} Hardware Item(s)</span>
                    <button
                        className="border text-sm text-white rounded-md hover:bg-slate-50 transition p-1.5"
                        onClick={() => setIsSidebarOpen(true)} // Toggle sidebar
                    >
                        <img className="h-5 w-5" src={MenuIcon} alt="Menu"/>
                    </button>
                    <button
                        className="border text-sm text-white rounded-md hover:bg-slate-50 transition p-1.5"
                        onClick={() => setShowAddHardware(true)}
                    >
                        <img className="h-5 w-5" src={Plus} alt="Add"/>
                    </button>
                </div>
            </div>
            <div className="overflow-hidden border-t border-slate-100">
                {Object.keys(groupedHardware).map((key) => (
                    <div key={key}>
                        <div className="bg-slate-100 py-1 px-7 flex space-x-2 items-center">
                            <h1 className={"text-sm font-semibold"}>{key}</h1>
                            <h1 className={"text-xs font-semibold bg-slate-200 rounded px-1 text-slate-500"}>{groupedHardware[key].length}</h1>
                        </div>
                        <div className="overflow-y-auto">
                            <table className="min-w-full">
                                <tbody>
                                {groupedHardware[key].map((item) => (
                                    <tr key={item.id}
                                        className="relative group flex border-b border-slate-100 items-center">
                                        <td className="py-3 pl-6 pr-8 w-10">{getBatteryLevelIcon(item.BatteryLevel)}</td>
                                        <td className="py-3 w-24 text-sm opacity-50 uppercase">{item.id}</td>
                                        <td className="py-3 w-10 p-2 scale-75">{getAccessLevelIcon(item.AccessLevel)}</td>
                                        <td className="py-3 flex-grow text-sm font-medium">{item.Name}</td>
                                        <td className="py-3 w-40 flex justify-end mr-3">
                                            <div className={"h-fit w-fit border rounded-full text-xs py-1 px-2"}>
                                                {item.siteName}
                                            </div>
                                        </td>
                                        <td className="py-3 w-20 flex justify-end">
                                            {renderLockStatus(item.openTime, item.closeTime)}
                                        </td>
                                        {!userIsGuest && <td className="w-6 mr-7 pr-0.5 opacity-0 group-hover:opacity-100 justify-center">
                                            <button
                                                className="group flex w-6 h-6 p-1 justify-between rounded-full hover:bg-slate-100 transition items-center"
                                                onClick={(e) => handleContextMenu(e, item)}
                                            >
                                                <div
                                                    className={"w-1 h-1 rounded-full bg-slate-400 group-hover:bg-slate-700 transition"}></div>
                                                <div
                                                    className={"w-1 h-1 rounded-full bg-slate-400 group-hover:bg-slate-700 transition"}></div>
                                                <div
                                                    className={"w-1 h-1 rounded-full bg-slate-400 group-hover:bg-slate-700 transition"}></div>
                                            </button>
                                        </td>}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
            {showAddHardware && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg">
                        <h2 className="text-lg font-semibold mb-4">Add New Hardware</h2>
                        <input
                            className="w-full mb-4 p-2 border rounded"
                            type="text"
                            placeholder="Enter hardware name"
                            value={newHardwareName}
                            onChange={(e) => setNewHardwareName(e.target.value)}
                        />
                        <input
                            className="w-full mb-4 p-2 border rounded"
                            type="text"
                            placeholder="Enter hardware type"
                            value={newHardwareType}
                            onChange={(e) => setNewHardwareType(e.target.value)}
                        />
                        <div className="flex justify-end space-x-4">
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={() => setShowAddHardware(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-green-500 text-white px-4 py-2 rounded"
                                onClick={handleAddHardware}
                            >
                                Add Hardware
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {contextMenuVisible && selectedHardware && (
                <div
                    className="absolute w-40 bg-white shadow-lg rounded-lg z-50 border right-6 overflow-clip"
                    style={{top: contextMenuPosition.y}}
                >
                    <button
                        className="group w-full text-left px-3 py-2 hover:bg-slate-50 transition flex space-x-1 items-center"
                        onClick={() => {
                            alert(`Hardware Details: ${JSON.stringify(selectedHardware, null, 2)}`);
                            setContextMenuVisible(false);
                        }}
                    >
                        <img src={Details} className={"opacity-30 group-hover:opacity-75 transition"}/>
                        <h1 className={"text-slate-300 group-hover:text-slate-700 transition text-sm font-medium"}>Details</h1>
                    </button>
                    <button
                        className="group w-full text-left px-3 py-2 hover:bg-red-50 transition flex space-x-1 items-center"
                        onClick={() => handleRemoveHardware(selectedHardware.id)}
                    >
                        <img src={Remove} className={"opacity-30 group-hover:opacity-75 transition"}/>
                        <h1 className={"text-slate-300 group-hover:text-red-700 transition text-sm font-medium"}>Remove</h1>
                    </button>
                </div>
            )}

            {/* Sidebar Menu */}
            <div
                className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg z-50 transform ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform`}
            >
                <div className="px-5 py-3 flex justify-between items-center border-b">
                    <h2 className="text-lg font-semibold">Options</h2>
                    <button onClick={() => setIsSidebarOpen(false)} className={"font-medium text-xl"}>&times;</button>
                </div>
                <div className="p-4 space-y-4">
                    <Dropdown
                        icon={GroupIcon}
                        options={[
                            {value: 'AccessLevel', label: 'Access Level'},
                            {value: 'BatteryLevel', label: 'Battery Level'},
                            {value: 'siteName', label: 'Site'},
                        ]}
                        onSelect={handleGroupBy}
                        functionName={"Grouping"}
                    />
                    <Dropdown
                        icon={SortIcon}
                        options={[
                            {value: 'BatteryLevel', label: 'Battery Level'},
                            {value: 'AccessLevel', label: 'Access Level'},
                            {value: 'type', label: 'Type'},
                            {value: 'siteName', label: 'Site'},
                        ]}
                        onSelect={handleSort}
                        functionName={"Sort By"}
                    />
                    <Dropdown
                        icon={FilterIcon}
                        options={[
                            {value: 'type', label: 'Type'},
                            {value: 'AccessLevel', label: 'Access Level'},
                            {value: 'BatteryLevel', label: 'Battery Level'},
                            {value: 'siteName', label: 'Site'},
                        ]}
                        onSelect={handleFilterBy}
                        functionName={"Filter By"}
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentHardwareTable;
