import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { addDoc, collection, getFirestore, serverTimestamp, doc, getDoc } from "@firebase/firestore";
import { getAuth, sendEmailVerification } from "firebase/auth";  // Import for email verification
import Autocomplete from 'react-google-autocomplete';
import MooslixLogoBlack from "../../assets/images/MooslixLogoBlack.svg";
import MooslixLogoTextBlack from "../../assets/images/MooslixLogoTextBlack.svg";
import NextIcon from "../../assets/icons/RightChevronBlack.svg";
import PlusIcon from "../../assets/icons/PlusBlack.svg";
import DefaultUserIcon from "../../assets/icons/PlusBlack.svg";
import homeIcon from "../../assets/icons/PlusBlack.svg";
import businessIcon from "../../assets/icons/PlusBlack.svg";
import institutionIcon from "../../assets/icons/PlusBlack.svg";
import SignUpHand from "../../assets/images/SignUpHand.png";

const Signup = () => {
  const navigate = useNavigate();
  const { signup } = useAuth();
  const db = getFirestore();
  const auth = getAuth();  // Get auth instance
  const userCollection = collection(db, "user_data");
  const orgCollection = collection(db, "organization_data");

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    orgType: '',
    orgName: '',
    orgAddress: '',
    siteNumber: '',
    siteName: '',
    siteAddress: '',
    hardwareId: '',
    hardwareName: '',
    hardwareSite: '',
    sites: [],
    hardware: [],
    confirmPassword: ''
  });
  const orgTypeIcons = {
    Home: homeIcon,
    Institution: institutionIcon,
    Business: businessIcon
  };

  const autocompleteRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const existingScript = document.getElementById('googleMaps');
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_GOOGLE_MAPS_API_KEY&libraries=places`;
        script.id = 'googleMaps';
        document.body.appendChild(script);
        script.onload = () => {
          const autocomplete = new window.google.maps.places.Autocomplete(
            autocompleteRef.current,
            { types: ['address'], componentRestrictions: { country: 'us' } }
          );
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.formatted_address) {
              setFormData(prevFormData => ({ ...prevFormData, orgAddress: place.formatted_address }));
            }
          });
        };
      }
    };

    loadGoogleMapsScript();
  }, [autocompleteRef]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const verifyHardwareId = async (hardwareId) => {
    const docRef = doc(db, "hardware", hardwareId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists();
  };

  const handleAddressSelect = (address) => {
    setFormData(prevFormData => ({ ...prevFormData, siteAddress: address }));
    console.log('Selected Address:', address);
  };

  const toggleSelection = (option) => {
    setSelectedOptions(prevSelectedOptions =>
      prevSelectedOptions.includes(option)
        ? prevSelectedOptions.filter(o => o !== option)
        : [...prevSelectedOptions, option]
    );
  };

  const handleNextStep = () => {
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    } else if (step === 3 && formData.sites.length === 0) {
      alert("No Sites");
      return;
    } else if (step === 4 && formData.hardware.length === 0) {
      alert("No Hardware Added");
      return;
    } else {
      setStep(step + 1);
    }
    console.log("Proceeding to the next step");
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const addSite = () => {
    if (formData.siteName && formData.siteAddress) {
      setFormData(prevFormData => ({
        ...prevFormData,
        sites: [...prevFormData.sites, {
          number: (prevFormData.sites.length + 1).toString(),
          name: formData.siteName,
          address: formData.siteAddress
        }],
        siteNumber: '',
        siteName: '',
        siteAddress: ''
      }));
    }
    console.log(formData.sites);
  };

  const addHardware = async () => {
    if (formData.hardwareId && formData.hardwareSite && formData.hardwareName) {
      const hardwareExists = await verifyHardwareId(formData.hardwareId);
      if (!hardwareExists) {
        alert("Hardware ID does not exist. Please enter a valid ID.");
        return;
      }

      setFormData(prevFormData => ({
        ...prevFormData,
        hardware: [
          ...prevFormData.hardware,
          {
            id: formData.hardwareId,
            name: formData.hardwareName,
            associatedSite: formData.hardwareSite
          }
        ],
        hardwareId: '',
        hardwareName: '',
        hardwareSite: ''
      }));
    }
  };

  const parseAddress = (address) => {
    if (address !== undefined && address !== null) {
      try {
        const fullAddress = address;
        const parts = address.split(',');
        const country = parts.pop().trim();
        const zipAndState = parts.pop().trim().split(' ');
        const zipCode = zipAndState.pop().trim();
        const city = parts.pop();
        const street = parts.join(',').trim();
        const state = zipAndState.pop().trim();

        return {
          fullAddress,
          street,
          city,
          state,
          zipCode,
          country
        };
      } catch {
        console.error("Couldn't trim");
        return null;
      }
    } else {
      console.error("Variable is undefined");
      return null;
    }
  };

  const generateOrgID = () => {
    return `${Date.now()}${Math.floor(Math.random() * 10000)}`;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const parsedAddress = parseAddress(formData.orgAddress);

    if (!parsedAddress) {
      console.error("Address parsing failed");
      return;
    }

    try {
      const userCredential = await signup(formData.email, formData.password);
      const user = userCredential.user;

      await addDoc(userCollection, {
        User_ID: user.uid,
        Email: formData.email,
        FirstName: formData.firstName,
        LastName: formData.lastName,
        ProfileURL: "profile_photos/defaultUser.png"
      });

      await addDoc(orgCollection, {
        ID: generateOrgID(),
        Type: formData.orgType,
        Name: formData.orgName,
        Address: parsedAddress.fullAddress,
        Logo: "org_logos/DefaultOrgIcon.png",
        MemberLogs: [],
        Member_Emails: [formData.email],
        Status: ["admin"],
        Street: parsedAddress.street,
        City: parsedAddress.city,
        State: parsedAddress.state,
        Zip_Code: parsedAddress.zipCode,
        Timestamp: serverTimestamp(),
        EnabledFeatures: selectedOptions
      });

      // Send Email Verification
      await sendEmailVerification(user);

      console.log("User and organization data added successfully.");
      alert("Account created successfully! Please check your email to verify your address.");
      navigate("/login");
    } catch (error) {
      console.error("Error:", error.code, error.message);
    }
  };

  const progress = ((step - 1) / 4) * 100;

  return (
    <main className={"w-screen h-screen flex justify-center items-center"}>
      <section className={"w-full h-full flex"}>
        <div className={"md:w-3/5 w-full h-full bg-white flex relative"}>
          <div className={"absolute top-4 px-4 w-full h-10 flex justify-between"}>
            <a className={"flex w-0 md:w-fit invisible md:visible"} href={"/"}>
              <img className={"h-10"} src={MooslixLogoBlack}/>
              <img className={"h-10"} src={MooslixLogoTextBlack}/>
            </a>
            <div className={"flex w-full justify-between md:space-x-3 md:w-fit h-full items-center"}>
              <div
                  className={`text-sm border w-fit h-fit px-2 py-1 rounded-full transition ${step === 1 ? "border-primary bg-primary bg-opacity-10 text-primary" : "text-gray-400"}`}>
                1. User
              </div>
              <img className={"h-2.5 w-2.5 opacity-10"} src={NextIcon}/>
              <div
                  className={`text-sm border w-fit h-fit px-2 py-1 rounded-full transition ${step === 2 ? "border-primary bg-primary bg-opacity-10 text-primary" : "text-gray-400"}`}>
                2. Organization
              </div>
              <img className={"h-2.5 w-2.5 opacity-10"} src={NextIcon}/>
              <div
                  className={`text-sm border w-fit h-fit px-2 py-1 rounded-full transition ${step === 3 ? "border-primary bg-primary bg-opacity-10 text-primary" : "text-gray-400"}`}>
                3. Sites
              </div>
              <img className={"h-2.5 w-2.5 opacity-10"} src={NextIcon}/>
              <div
                  className={`text-sm border w-fit h-fit px-2 py-1 rounded-full transition ${step === 4 ? "border-primary bg-primary bg-opacity-10 text-primary" : "text-gray-400"}`}>
                4. Hardware
              </div>
            </div>
          </div>
          <div className="pt-10 w-full h-full">
            {step === 1 && (
                <div className="w-full h-full flex flex-col md:p-16 p-4 items-center justify-center">
                  <div className="w-full h-full max-w-lg max-h-xl rounded-lg flex flex-col py-6">
                  <h1 className="text-3xl font-semibold">Sign Up</h1>
                  <p className="text-md opacity-50 font-normal w-11/12 mt-2">Enter your details to create an account or log into your current Mooslix account.</p>
                  <div className="mt-4 flex flex-col space-y-6">
                    <div className={"flex flex-col"}>
                      <h1 className={"text-sm font-medium"}>Full Name</h1>
                      <div className={"w-full h-fit flex space-x-2"}>
                        <input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={formData.firstName}
                          onChange={handleChange}
                          className="mt-2 p-2 border border-gray-300 rounded w-1/2"
                        />
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={handleChange}
                          className="mt-2 p-2 border border-gray-300 rounded w-1/2"
                        />
                      </div>
                    </div>
                    <div className={"flex flex-col w-full h-fit mt-2"}>
                      <h1 className={"text-sm font-medium"}>E-Mail Address</h1>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="mt-2 p-2 border border-gray-300 rounded"
                      />
                    </div>
                    <div className={"flex flex-col w-full h-fit mt-2"}>
                      <h1 className={"text-sm font-medium"}>Password</h1>
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        className="mt-2 p-2 border border-gray-300 rounded"
                      />
                      <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className="mt-2 p-2 border border-gray-300 rounded"
                      />
                    </div>
                  </div>
                  <div className={"w-full md:flex-grow h-0"}></div>
                  <button onClick={handleNextStep} className="mt-4 p-2 bg-blue-500 text-white rounded">Next</button>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="w-full h-full flex flex-col md:p-16 p-4 items-center justify-center">
                <div className="w-full h-full max-w-lg max-h-lg rounded-lg flex flex-col py-6">
                  <h1 className="text-3xl font-semibold">Organization Registration</h1>
                  <p className="text-md opacity-50 font-normal w-11/12 mt-2">Enter your organization details.</p>
                  <div className="mt-4 w-full h-fit">
                    <h1 className={"text-sm font-medium"}>Organization Name</h1>
                    <input
                      type="text"
                      name="orgName"
                      placeholder="Organization Name"
                      value={formData.orgName}
                      onChange={handleChange}
                      className="mt-2 p-2 border border-gray-300 rounded w-full"
                    />
                  </div>
                  <div className="mt-3 w-full h-fit">
                    <h1 className={"text-sm font-medium"}>Type</h1>
                    <div className={"h-fit w-full flex space-x-3"}>
                      <div
                        className={`mt-2 p-2 flex justify-between border rounded w-1/3 h-full cursor-pointer transition ${formData.orgType === "Home" ? "border-blue-400 bg-blue-50" : "hover:border-blue-400 border-gray-300"}`}
                        onClick={() => setFormData(prevFormData => ({ ...prevFormData, orgType: 'Home' }))}
                      >
                        Home
                        <img src={homeIcon}/>
                      </div>
                      <div
                        className={`mt-2 p-2 flex justify-between border rounded w-1/3 h-full cursor-pointer transition ${formData.orgType === "Institution" ? "border-blue-400 bg-blue-50" : "hover:border-blue-400 border-gray-300"}`}
                        onClick={() => setFormData(prevFormData => ({ ...prevFormData, orgType: 'Institution' }))}
                      >
                        Institution
                        <img src={institutionIcon}/>
                      </div>
                      <div
                        className={`mt-2 p-2 flex justify-between border rounded w-1/3 h-full cursor-pointer transition ${formData.orgType === "Business" ? "border-blue-400 bg-blue-50" : "hover:border-blue-400 border-gray-300"}`}
                        onClick={() => setFormData(prevFormData => ({ ...prevFormData, orgType: 'Business' }))}
                      >
                        Business
                        <img className={""} src={businessIcon}/>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <button onClick={handlePrevStep} className="p-2 bg-gray-500 text-white rounded">Back</button>
                    <button onClick={handleNextStep} className="p-2 bg-blue-500 text-white rounded">Next</button>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="w-full h-full flex flex-col md:p-16 p-4 items-center justify-center">
                <div className="w-full h-full max-w-lg max-h-lg rounded-lg flex flex-col py-6">
                  <h1 className="text-3xl font-semibold">Sites</h1>
                  <p className="text-md opacity-50 font-normal w-11/12 mt-2">Type in the number on your device's screen
                    to connect.</p>
                  <h1 className={"text-sm font-medium mt-3"}>Add Sites</h1>
                  <div className={"flex-grow w-full flex flex-col"}>
                    <div className={"border flex-grow w-full rounded px-3 py-2"}>
                      <input
                          type="text"
                          name="siteName"
                          placeholder="Site Name"
                          value={formData.siteName}
                          onChange={handleChange}
                          className="mt-2 p-2 border border-gray-300 rounded w-full"
                      />
                      <Autocomplete
                          apiKey="YOUR_GOOGLE_MAPS_API_KEY"
                          onPlaceSelected={(place) => {
                            setFormData(prevFormData => ({
                              ...prevFormData,
                              siteAddress: place.formatted_address
                            }));
                          }}
                          ref={autocompleteRef}
                          options={{types: ['address'], componentRestrictions: {country: 'us'}}}
                          className="mt-2 p-2 border border-gray-300 rounded w-full"
                      />
                      <div
                          className={"h-fit text-sm flex justify-between items-center bg-black text-white cursor-pointer py-1 pr-0 pl-3 rounded-full font-medium mt-3 hover:opacity-50 transition"}
                          onClick={addSite}
                      >
                        <h1 className={"text-sm"}>Add Site to {formData.orgName}</h1>
                        <img className={"mr-1 h-5 w-5 bg-white rounded-full"} src={PlusIcon}/>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <button onClick={handlePrevStep} className="p-2 bg-gray-500 text-white rounded">Back</button>
                    <button onClick={handleNextStep} className="p-2 bg-blue-500 text-white rounded">Next</button>
                  </div>
                </div>
              </div>
            )}
            {step === 4 && (
                <div className="w-full h-full flex flex-col md:p-16 p-4 items-center justify-center">
                  <div className="w-full h-full max-w-lg max-h-lg rounded-lg flex flex-col py-6">
                    <h1 className="text-3xl font-semibold">Hardware Connection</h1>
                    <p className="text-md opacity-50 font-normal w-11/12 mt-2">Type in the number on your device's
                      screen to connect.</p>
                    <div className={"p-4 flex flex-col relative border rounded-lg flex-grow space-y-4 mt-4"}>
                      <div className="flex space-x-2">
                        <input
                            type="text"
                            name="hardwareId"
                            placeholder="Hardware ID (####-####)"
                            value={formData.hardwareId}
                            onChange={handleChange}
                            className="p-2 border border-gray-300 rounded flex-grow"
                        />
                        <div className={"w-20 h-20 border rounded-md"}></div>
                      </div>
                      <div className="flex flex-col">
                        <h1 className={"text-sm font-medium"}>Associated Site</h1>
                        <select
                            name="hardwareSite"
                            value={formData.hardwareSite}
                            onChange={handleChange}
                            className="mt-2 p-2 border border-gray-300 rounded w-full"
                        >
                          <option value="">Select Site</option>
                          {formData.sites.map((site, index) => (
                              <option key={index} value={site.name}>
                              {site.name} - {site.address}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <h1 className={"text-sm font-medium"}>Hardware Name</h1>
                      <input
                        type="text"
                        name="hardwareName"
                        placeholder="Hardware Name"
                        value={formData.hardwareName}
                        onChange={handleChange}
                        className="mt-2 p-2 border border-gray-300 rounded"
                      />
                    </div>
                    <div className={"absolute bottom-6 left-4 px-2 py-1 border rounded-full flex items-center cursor-pointer transition hover:border-gray-500"} onClick={addHardware}>
                      Add Device
                      <img className={"h-6 w-6"} src={PlusIcon}/>
                    </div>
                    <div className={"absolute bottom-6 right-4 w-9 h-9 aspect-square border rounded-full flex items-center justify-center border-gray-500"}>
                      {formData.hardware.length}
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <button onClick={handlePrevStep} className="p-2 bg-gray-500 text-white rounded">Back</button>
                    <button onClick={handleNextStep} className="p-2 bg-blue-500 text-white rounded">Next</button>
                  </div>
                </div>
              </div>
            )}
            {step === 5 && (
              <div className="w-full h-full flex flex-col md:p-16 p-4 items-center justify-center">
                <div className="w-full h-full max-w-lg max-h-xl rounded-lg flex flex-col py-6">
                  <h1 className="text-3xl font-semibold">Confirm Registration</h1>
                  <p className="text-md opacity-50 font-normal w-11/12 mt-2">Personalize your organization right away. This can be changed later in settings.</p>
                  <div className="mt-4 flex flex-col space-y-6">
                    <div className={"flex flex-col"}>
                      <h1 className={"text-sm font-medium"}>Full Name</h1>
                      <div className={"w-full h-fit flex space-x-2"}>
                      </div>
                      <div className={"w-full md:flex-grow h-0"}></div>
                      <div className="flex justify-between mt-4">
                        <button onClick={handlePrevStep} className="p-2 bg-gray-500 text-white rounded">Back</button>
                        <button onClick={onSubmit} className="p-2 bg-green-500 text-white rounded">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"w-0 invisible md:visible md:w-2/5 md:min-w-96 h-full bg-primary md:px-16 md:py-8 flex flex-col justify-end space-y-3 relative overflow-clip"}>
          <div className={"absolute w-full h-full -bottom-40 left-0"}><img className={"w-full h-full object-cover"} src={SignUpHand}/></div>
          <div className={"absolute left-0 top-6 w-full h-fit flex flex-col text-white text-4xl font-medium content-center justify-center items-center text-center px-10"}>
            Secure Your Future with Advanced Biometrics
          </div>
          {formData.firstName &&
            <div className={"h-fit w-full bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl shadow-lg border border-white border-opacity-20 p-4 flex justify-between"}>
              <div className={"flex items-center"}>
                <img className={"opacity-100 w-5 h-5 scale-125"} src={DefaultUserIcon}/>
                <h1 className={"pl-3"}>{formData.firstName + " " + formData.lastName}</h1>
              </div>
              <div className={"opacity-50"}>{formData.email}</div>
            </div>
          }
          {formData.orgName &&
            <div className={"h-fit w-full bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl shadow-lg border border-white border-opacity-20 p-4 flex flex-col"}>
              <div className={"flex justify-between"}>
                <div className={"flex items-center"}>
                  <img className={"opacity-100 w-5 h-5 scale-110"} src={orgTypeIcons[formData.orgType]} />
                  <h1 className={"pl-3 font-semibold"}>{formData.orgName}</h1>
                </div>
                <div className={"opacity-50"}>{formData.orgType}</div>
              </div>
              <div className="relative mt-4 h-fit">
                <ul className="flex flex-col">
                  {formData.sites.map((site, index) => {
                    const associatedHardware = formData.hardware.filter(item => item.associatedSite === site.name);

                    return (
                      <li key={index} className="flex flex-col relative pb-2">
                        <div className={"absolute left-2 top-0 border-l-4 border-b-4 rounded-bl-lg border-black h-1/2 w-4"}></div>
                        {formData.sites[index + 1] &&
                          <div className={"absolute left-2 top-0 border-l-4 border-black h-full w-4"}></div>
                        }
                        <div className={"py-2 px-4 flex-grow flex h-fit ml-8 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-xl shadow-lg border border-white border-opacity-20 flex-col"}>
                          <div className={"h-fit w-full flex flex-col"}>
                            <div className="font-medium">{site.name}</div>
                            <div className="text-xs opacity-60">{site.address}</div>
                          </div>
                          {associatedHardware.length > 0 && (
                            <ul className={"flex flex-col space-y-2 border-t border-black py-2 mt-1"}>
                              {associatedHardware.map((item, hwIndex) => (
                                <li key={hwIndex} className={"flex space-x-2"}>
                                  <div className={"h-10 w-10  bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded shadow-lg border border-white border-opacity-20 "}></div>
                                  <div className={"flex flex-col h-10 w-fit justify-between"}>
                                    <div className={"font-medium"}>{item.name}</div>
                                    <div className={"text-xs opacity-80"}>{item.name}</div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          }
        </div>
      </section>
    </main>
  );
};

export default Signup;


