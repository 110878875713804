import React from 'react';

const LoadingSpinner = () => {
    const spinnerStyle = {
        width: '40px',
        height: '40px',
        position: 'relative',
        margin: 'auto',
    };

    const bounceStyle = {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        backgroundColor: '#60a5fa',
        opacity: 0.6,
        position: 'absolute',
        top: 0,
        left: 0,
        animation: 'sk-bounce 2s infinite ease-in-out',
    };

    return (
        <div className="flex justify-center items-center h-32">
            <div className={""} style={spinnerStyle}>
                <div style={{ ...bounceStyle, animationDelay: '-1s' }}></div>
                <div style={bounceStyle}></div>
            </div>
            <style>
                {`
                    @keyframes sk-bounce {
                        0%, 100% {
                            transform: scale(0);
                        }
                        50% {
                            transform: scale(1);
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default LoadingSpinner;
