import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { fetchOrganizationData, fetchSites } from '../../services/firestoreService'; // Adjust the path as necessary

const SelectSites = ({ orgId, onSelect }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const longestOptionRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Assuming orgId is used to fetch the organization's site IDs
                const orgData = await fetchOrganizationData(orgId);
                const sitesData = await fetchSites(orgId)
                const siteOptions = sitesData.map(site => ({
                    value: site.Name,
                    label: site.Name,
                }));
                setOptions([{ value: 'all', label: 'All Sites' }, ...siteOptions]);
                setSelectedOption({ value: 'all', label: 'All Sites' });
            } catch (error) {
                console.error('Error fetching site data:', error);
            }
        };

        fetchData();
    }, [orgId]);

    const handleChange = selectedOption => {
        if (selectedOption.value === 'all') {
            setSelectedOption({ value: 'all', label: 'All Sites' });
        } else {
            setSelectedOption(selectedOption);
        }
        onSelect(selectedOption);
    };

    useEffect(() => {
        if (longestOptionRef.current) {
            const longestOption = options.reduce((longest, option) => {
                const optionLength = option.label.length;
                return optionLength > longest.length ? option : longest;
            }, { label: '' });

            longestOptionRef.current.innerHTML = longestOption.label;
        }
    }, [options]);

    return (
        <div className={"w-fit h-12 flex items-center"}>
            <Select
                value={selectedOption}
                onChange={handleChange}
                options={options}
                components={{ Option: CustomOption }}
                styles={customStyles}
            />
        </div>
    );
};

const CustomOption = props => {
    return (
        <components.Option {...props}>
            <div className="flex items-center">
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    className="mr-2"
                />
                <label>{props.label}</label>
            </div>
        </components.Option>
    );
};

const customStyles = {
    control: (provided) => ({
        ...provided,
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        borderRadius: '0',
        padding: '0',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        minWidth: 'auto',
    }),
    menu: (provided) => ({
        ...provided,
        minWidth: 'auto',
    }),
    option: (provided, state) => ({
        ...provided,
        color: 'inherit',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'inherit',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'inherit',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
};

export default SelectSites;
