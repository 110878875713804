import {fetchSites, fetchPaymentHardware, fetchTransactions, getLastWeekRevenueData} from "./firestoreService";
import {db} from "../firebaseConfig";

const calculateInsights = (transactions) => {
  if (!transactions || transactions.length === 0) {
    return { totalIncome: 0, averageTransactionValue: 0, transactionCount: 0 };
  }
  const totalIncome = transactions.reduce((total, transaction) => total + (transaction.Amount || 0), 0);
  const averageTransactionValue = totalIncome / transactions.length;
  return { totalIncome, averageTransactionValue, transactionCount: transactions.length };
};

const calculateCustomerTypeBreakdown = (transactions) => {
  const breakdown = {};
  transactions.forEach(transaction => {
    const type = transaction.CustomerType || 'Unknown'; // Assuming 'Unknown' for transactions without a customer type
    breakdown[type] = (breakdown[type] || 0) + 1;
  });
  return breakdown;
};

const calculateSiteTrafficBreakdown = (transactions) => {
  const breakdown = {};
  transactions.forEach(transaction => {
    const type = transaction.SiteName || 'Unknown'; // Assuming 'Unknown' for transactions without a customer type
    breakdown[type] = (breakdown[type] || 0) + 1;
  });
  return breakdown;
};


// Main function to get dashboard data
export const getDashboardData = async (orgId) => {
  try {
    let cashflowArray
    const sites = await fetchSites(orgId);
    const allTransactions = [];

    // Loop through each site
    for (const site of sites) {
        const hardwareArray = await fetchPaymentHardware(orgId, site.id);

        // Loop through each hardware within the current site
        for (const hardware of hardwareArray) {
            const transactions = await fetchTransactions(orgId, hardware.siteId, hardware.id);

            // Add SiteName to each transaction and push it to the allTransactions array
            transactions.forEach(transaction => {
                allTransactions.push({
                    ...transaction,
                    SiteName: site.Name // Add the SiteName field based on the current site
                });
            });
        }
    }

    console.log("All Transactions:", allTransactions); // Verify the structure

    // Calculate insights if there are transactions
    let insights = { totalIncome: 0, averageTransactionValue: 0, transactionCount: 0 };
    if (allTransactions.length > 0) {
      insights = calculateInsights(allTransactions);
      const customerTypeBreakdown = calculateCustomerTypeBreakdown(allTransactions);
      insights.customerTypeBreakdown = customerTypeBreakdown;
      const siteTransactionTrafficBreakdown = calculateSiteTrafficBreakdown(allTransactions)
      insights.siteTransactionTrafficBreakdown = siteTransactionTrafficBreakdown;
    }

    const result = await getLastWeekRevenueData(orgId);
    if (result && typeof result === 'object' && !Array.isArray(result)) {
      const dataArray = Object.keys(result).map(key => ({
        day: key,
        ...result[key]
      }));
      cashflowArray = (dataArray);
    } else {
      console.error("Received data is not in expected format:", result);
    }

    // Return the data along with customer type breakdown
    return {
      totalIncome: insights.totalIncome,
      totalExpenditures: 0, // Adjust or fetch this data
      averageTransactionValue: insights.averageTransactionValue,
      transactionCount: insights.transactionCount,
      customerTypeBreakdown: insights.customerTypeBreakdown || {},
      siteTransactionTrafficBreakdown: insights.siteTransactionTrafficBreakdown || {},
      cashflowArray: cashflowArray
    };
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};
