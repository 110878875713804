import React from "react";
import MooslixText from "../../assets/images/MooslixLogoTextBlack.svg"
import Next from "../../assets/icons/RightChevronBlack.svg"
import instagramIcon from "../../assets/icons/InstagramIcon.svg"
import linkedInIcon from "../../assets/icons/LinkedInIcon.svg"
import gitHubIcon from "../../assets/icons/GitHubIcon.svg"

export default function Footer() {

    return (
        <div className="overflow-clip md:h-screen h-fit w-full flex flex-col pt-28">
            <div className={"w-full flex-grow py-10 flex md:flex-row flex-col justify-between bg-gradient-to-b from-white to-slate-100 border-b border-slate-200 px-4 md:px-12"}>
                <div className={"flex flex-col w-fit h-full justify-end md:mb-0 mb-4"}>
                    <h1 className={"text-6xl font-bold text-slate-800"}>Plan Today.</h1>
                    <h1 className={"text-6xl font-bold text-slate-800"}>Build Tomorrow.</h1>
                </div>
                <div className={"flex w-fit h-full items-end space-x-3"}>
                    <button className={"text-lg font-semibold text-slate-800 bg-slate-200 rounded-xl py-2 px-4"}>Try Now</button>
                    <button className={"text-lg font-semibold bg-slate-800 text-slate-200 py-2 rounded-xl px-4"}>Contact Us</button>
                </div>
            </div>
            <div className={"w-full flex-grow py-10 md:flex justify-between px-4 md:px-12 grid grid-cols-1 gap-8"}>
                <div className={"md:w-1/2 w-full h-fit flex justify-between"}>
                    <div className={"w-1/2 h-full md:flex text-[1rem]"}>
                        <h1 className={"font-semibold mr-4"}>Products</h1>
                        <ul className={"font-medium space-y-2 flex flex-col"}>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}><a
                                href={"/platform"}>Platform</a></li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}><a
                                href={"/payments"}>Hardware</a></li>
                        </ul>
                    </div>
                    <div className={"w-1/2 h-full md:flex text-[1rem]"}>
                        <h1 className={"font-semibold mr-4"}>Careers</h1>
                        <ul className={"font-medium space-y-2 flex flex-col"}>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Blog</li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Press</li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Internships</li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Support</li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Help
                                Center
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={"md:w-1/2 w-full h-fit flex justify-between"}>
                    <div className={"w-1/2 h-full md:flex text-[1rem]"}>
                        <h1 className={"font-semibold mr-4"}>Resources</h1>
                        <ul className={"font-medium space-y-2 flex flex-col"}>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Events</li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Community</li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Social
                                Media
                            </li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Newsletter</li>
                        </ul>
                    </div>
                    <div className={"w-1/2 h-full md:flex text-[1rem]"}>
                        <h1 className={"font-semibold mr-4"}>Developer</h1>
                        <ul className={"font-medium space-y-2 flex flex-col"}>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>API</li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>Status</li>
                            <li className={"cursor-pointer hover:opacity-75 opacity-40 text-black transition"}>GitHub</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"w-full h-fit py-4 flex flex-col space-y-2 px-4 md:px-12"}>
                <hr/>
                <div className={"h-fit w-full flex justify-between"}>
                    <div className={"flex space-x-4 h-full w-fit content-center items-center"}>
                        <img className={"h-2/3"} src={MooslixText}/>
                    </div>
                    <ul className={"flex space-x-4 md:h-full h-16 w-fit content-center items-center"}>
                        <li className={"relative h-1/2 aspect-square bg-black rounded-lg hover:shadow-xl shadow transition cursor-pointer flex items-center justify-center"}>
                            <a href={"https://www.linkedin.com/company/mooslix/"}
                               className={"absolute w-full h-full top-0 left-0"}></a>
                            <img src={instagramIcon}/>
                        </li>
                        <li className={"relative h-1/2 aspect-square bg-black rounded-lg hover:shadow-xl shadow transition cursor-pointer flex items-center justify-center"}>
                            <a href={"https://www.linkedin.com/company/mooslix/"}
                               className={"absolute w-full h-full top-0 left-0"}></a>
                            <img src={linkedInIcon}/>
                        </li>
                        <li className={"relative h-1/2 aspect-square bg-black rounded-lg hover:shadow-xl shadow transition cursor-pointer flex items-center justify-center"}>
                            <a href={"https://www.linkedin.com/company/mooslix/"} className={"absolute w-full h-full top-0 left-0"}></a>
                            <img src={gitHubIcon}/>
                        </li>
                    </ul>
                </div>
                <hr/>
                <div className={"h-fit w-full flex justify-between"}>
                    <ul className={"flex space-x-4"}>
                        <li className={"text-2xs md:text-xs text-slate-400 hover:text-slate-900 transition cursor-pointer"}><a>Privacy Policy</a></li>
                        <li className={"text-2xs md:text-xs text-slate-400 hover:text-slate-900 transition cursor-pointer"}><a>Terms of Service</a></li>
                        <li className={"text-2xs md:text-xs text-slate-400 hover:text-slate-900 transition cursor-pointer"}><a>Cookie Policy</a></li>
                    </ul>
                    <h1 className={"text-2xs md:text-xs opacity-50"}>© 2024 Mooslix Inc. All Rights Reserved</h1>
                </div>
            </div>
        </div>
    );
}
