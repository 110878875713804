import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig'; // Adjust the path as necessary
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import BillingHistoryTable from "../layout/BillingHistoryTable";
import StructureDiagram from "../layout/Structure";

const OrganizationSettings = ({ orgId }) => {
    const [activeTab, setActiveTab] = useState('Appearance');
    const [orgData, setOrgData] = useState({
        Name: '',
        Logo: '',
        Structure: '',
        BillingInfo: '',
    });
    const [loading, setLoading] = useState(true);
    const [logoFile, setLogoFile] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        const fetchOrgData = async () => {
            if (orgId) {
                const orgDocRef = doc(db, 'organization_data', orgId);
                const orgDoc = await getDoc(orgDocRef);
                if (orgDoc.exists()) {
                    const data = orgDoc.data();
                    setOrgData(data);

                    // Fetch the logo URL from storage
                    if (data.Logo) {
                        try {
                            const logoRef = ref(storage, data.Logo);
                            const url = await getDownloadURL(logoRef);
                            setLogoUrl(url);
                        } catch (error) {
                            console.error('Error fetching logo URL:', error);
                        }
                    }
                }
            }
            setLoading(false);
        };

        fetchOrgData();
    }, [orgId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOrgData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleLogoChange = (e) => {
        if (e.target.files[0]) {
            setLogoFile(e.target.files[0]);
        }
    };

    const handleSave = async () => {
        try {
            const orgDocRef = doc(db, 'organization_data', orgId);
            await updateDoc(orgDocRef, {
                Name: orgData.Name,
                Structure: orgData.Structure,
                BillingInfo: orgData.BillingInfo,
            });

            if (logoFile) {
                const logoRef = ref(storage, `org_logos/${orgId}`);
                const uploadTask = uploadBytesResumable(logoRef, logoFile);

                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        // You can add progress tracking here if desired
                    },
                    error => {
                        console.error('Error uploading logo:', error);
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        await updateDoc(orgDocRef, { Logo: downloadURL });
                        setLogoUrl(downloadURL);
                    }
                );
            }

            alert('Settings saved successfully');
        } catch (error) {
            console.error('Error saving settings:', error);
            alert('Failed to save settings');
        }
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Appearance':
                return (
                    <div className="space-y-4 flex flex-col">
                        <div className="flex w-full h-fit py-4 border-b space-x-4">
                            <div className={"flex flex-col space-y-0.5 w-2/5"}>
                                <h1 className={"font-semibold text-sm"}>Organization Name</h1>
                                <h1 className={"text-sm font-medium opacity-40"}>Change the name of your
                                    organization</h1>
                            </div>
                            <div className={"flex flex-col flex-grow"}>
                                <input
                                    type="text"
                                    name="Name"
                                    value={orgData.Name}
                                    onChange={handleInputChange}
                                    className="h-full p-2 border border-gray-300 rounded w-full"
                                />
                            </div>
                        </div>
                        <div className="flex w-full h-fit py-4 border-b space-x-4">
                            <div className={"flex flex-col space-y-0.5 w-2/5"}>
                                <h1 className={"font-semibold text-sm"}>Organization Logo</h1>
                                <h1 className={"text-sm font-medium opacity-40"}>Update your organization logo</h1>
                            </div>
                            <div className={"flex flex-grow space-x-4"}>
                                {logoUrl ? (
                                    <img
                                        src={logoUrl}
                                        alt="Org Logo"
                                        className="w-24 h-24 rounded-lg"
                                    />
                                ) : (
                                    <div className="w-24 h-24 rounded-lg bg-slate-100"></div>
                                )}
                                <div className="flex-grow h-24 rounded-lg border-slate-100 border-2 border-dashed flex items-center justify-center">
                                    <input
                                        type="file"
                                        name="Logo"
                                        onChange={handleLogoChange}
                                        className=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full h-fit py-4 border-b space-x-4">
                            <div className={"flex flex-col space-y-0.5 w-2/5"}>
                                <h1 className={"font-semibold text-sm"}>Brand Color</h1>
                                <h1 className={"text-sm font-medium opacity-40"}>Choose your organization's highlight color</h1>
                            </div>
                            <div className={"flex flex-grow space-x-4"}>

                            </div>
                        </div>
                    </div>
                );
            case 'Billing':
                return (
                    <div className="mt-4 w-full h-fit flex flex-col space-y-6">
                        <div className={"w-full h-64 flex space-x-4"}>
                            <div
                                className={"w-1/2 h-full rounded-lg bg-primary relative overflow-clip flex flex-col py-4 px-6"}>
                                <div
                                    className={"absolute h-full rounded-full aspect-square bg-white bg-opacity-50 -right-1/3 -top-1/2"}></div>
                                <div
                                    className={"absolute h-full rounded-full aspect-square bg-white bg-opacity-50 -right-1/3 -top-1/2 scale-125"}></div>
                                <h1 className={"font-medium text-white opacity-90"}>Current Subscription Plan</h1>
                                <div className={"flex items-baseline mt-4 h-fit text-white"}>
                                    <h1 className={"text-5xl font-semibold"}>Free</h1>
                                    <h1 className={"text-3xl font-semibold"}></h1>
                                </div>
                                <div className={"flex items-baseline mt-2 h-fit"}>
                                    <h1 className={"text-xl font-medium opacity-90 text-white"}>Business Plus</h1>
                                </div>
                                <div className={"flex items-baseline mt-auto"}>
                                    <h1 className={"text-sm opacity-90 text-black bg-white rounded-lg px-3 py-2 font-medium"}>Change
                                        Plan</h1>
                                </div>
                            </div>
                            <div className={"w-1/2 h-full rounded-lg border-2 flex flex-col py-4 px-6"}>
                                <h1 className={"text-xl font-medium opacity-40"}>Next Payment</h1>
                                <div className={"flex items-baseline mt-4 h-fit"}>
                                    <h1 className={"text-5xl font-semibold"}>$00</h1>
                                    <h1 className={"text-3xl font-semibold"}>.00</h1>
                                </div>
                                <div className={"flex items-baseline mt-2 h-fit"}>
                                    <h1 className={"text-xl font-medium opacity-90"}>on August 14th, 2024</h1>
                                </div>
                                <div className={"flex items-baseline mt-auto"}>
                                    <h1 className={"text-sm opacity-90 text-white bg-black rounded-lg px-3 py-2 font-medium"}>Manage
                                        Payments</h1>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full flex-grow overflow-hidden">
                            <div className="w-full h-full flex">
                                <div className="w-full h-full overflow-hidden">
                                    <BillingHistoryTable/>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'Plans':
                return (
                    <div className="mt-4 flex-grow w-full">
                        <StructureDiagram orgId={orgId}/>
                    </div>
                );
            case 'Danger Zone':
                return (
                    <div className="mt-4">
                        <h2 className="text-red-600 font-bold">Danger Zone</h2>
                        <p className="text-gray-700">Here you can perform dangerous actions like deleting the
                            organization.</p>
                        {/* Add any dangerous actions here */}
                        <button
                            onClick={handleSave}
                            className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                        >
                            Save Settings
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="w-full h-full bg-slate-50 px-6 py-3 flex flex-col">
            <div className="flex space-x-4 border-b mb-4">
                {['Appearance', 'Plans', 'Billing', 'Danger Zone'].map(tab => (
                    <button
                        key={tab}
                        onClick={() => setActiveTab(tab)}
                        className={`py-1 my-1 px-2 mr-2 transition rounded-lg text-sm font-medium ${activeTab === tab ? 'text-slate-900 bg-slate-200' : 'text-gray-500'}`}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            {renderTabContent()}
        </div>
    );
};

export default OrganizationSettings;
