import React, { useEffect, useState } from 'react';
import {
    fetchOrganizationData,
    fetchOrganizationMembers,
    fetchUsersByIds,
    fetchStorageImage,
    addMemberToOrganization,
    removeMemberFromOrganization,
    checkIfUserIsGuest
} from '../../services/firestoreService';
import Plus from "../../assets/icons/PlusBlack.svg";
import SortIcon from "../../assets/icons/SortBlack.svg";
import FilterIcon from "../../assets/icons/ConfigureIconBlack.svg";
import MembersIcon from "../../assets/icons/MembersBlack.svg";
import GroupIcon from "../../assets/icons/GroupIcon.svg";
import { ReactComponent as OwnerIcon } from "../../assets/icons/OwnerIcon.svg";
import { ReactComponent as StudentIcon } from "../../assets/icons/StudentIcon.svg";
import { ReactComponent as PublicIcon } from "../../assets/icons/PublicIcon.svg";
import { ReactComponent as ManagerIcon } from "../../assets/icons/ManagerIcon.svg";
import Dropdown from '../common/Dropdown';
import SignOutIcon from "../../assets/icons/SignOutBlack.svg"; // Adjust the path as necessary
import Details from '../../assets/icons/DetailsBlack.svg'
import Remove from '../../assets/icons/RemoveRed.svg'
import LoadingSpinner from "../common/Loading";

const MemberList = ({ orgId }) => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'status', direction: 'ascending' });
    const [filterConfig, setFilterConfig] = useState({ key: '', value: '' });
    const [groupBy, setGroupBy] = useState('status');
    const [orgDetails, setOrgDetails] = useState(null);
    const [orgLogoUrl, setOrgLogoUrl] = useState('');
    const [showAddMember, setShowAddMember] = useState(false);
    const [newMemberEmail, setNewMemberEmail] = useState('');
    const [newMemberStatus, setNewMemberStatus] = useState('');
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [selectedMember, setSelectedMember] = useState(null);
    const [userIsGuest, setUserIsGuest] = useState(false);

    // State for sidebar visibility
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        const checkUserStatus = async () => {
            const isGuest = await checkIfUserIsGuest(orgId);
            setUserIsGuest(isGuest);
        };
        const getOrgDetails = async () => {
            try {
                const details = await fetchOrganizationData(orgId);
                setOrgDetails(details);

                if (details.Logo) {
                    const orgLogoUrl = await fetchStorageImage(details.Logo);
                    setOrgLogoUrl(orgLogoUrl);
                }
            } catch (error) {
                console.error('Error fetching organization details:', error);
            }
        };

        const getMembers = async () => {
            try {
                const { memberIds, statuses } = await fetchOrganizationMembers(orgId);
                const usersData = await fetchUsersByIds(memberIds);
                const membersWithStatus = usersData.map(user => ({
                    ...user,
                    status: statuses[memberIds.indexOf(user.id)]
                }));

                // Fetch profile pictures
                const membersWithProfilePics = await Promise.all(membersWithStatus.map(async member => {
                    if (member.ProfileURL) {
                        const profilePicUrl = await fetchStorageImage(member.ProfileURL);
                        return { ...member, ProfilePicUrl: profilePicUrl };
                    }
                    return member;
                }));

                setMembers(membersWithProfilePics);
            } catch (error) {
                console.error('Error fetching members:', error);
            } finally {
                setLoading(false);
            }
        };

        checkUserStatus();
        getOrgDetails();
        getMembers();
    }, [orgId]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleFilter = (key, value) => {
        setFilterConfig({ key, value });
    };

    const handleGroupBy = (key) => {
        setGroupBy(key);
    };

    const handleAddMember = async () => {
        try {
            await addMemberToOrganization(orgId, newMemberEmail, newMemberStatus);
            setShowAddMember(false);
            setNewMemberEmail('');
            setNewMemberStatus('');
            // Refresh the member list
            const { memberIds, statuses } = await fetchOrganizationMembers(orgId);
            const usersData = await fetchUsersByIds(memberIds);
            const membersWithStatus = usersData.map(user => ({
                ...user,
                status: statuses[memberIds.indexOf(user.id)]
            }));

            // Fetch profile pictures
            const membersWithProfilePics = await Promise.all(membersWithStatus.map(async member => {
                if (member.ProfileURL) {
                    const profilePicUrl = await fetchStorageImage(member.ProfileURL);
                    return { ...member, ProfilePicUrl: profilePicUrl };
                }
                return member;
            }));

            setMembers(membersWithProfilePics);
        } catch (error) {
            console.error('Error adding member:', error);
        }
    };

    const handleRemoveMember = async (memberId) => {
        try {
            await removeMemberFromOrganization(orgId, memberId);
            setContextMenuVisible(false);
            setSelectedMember(null);
            // Refresh the member list
            const { memberIds, statuses } = await fetchOrganizationMembers(orgId);
            const usersData = await fetchUsersByIds(memberIds);
            const membersWithStatus = usersData.map(user => ({
                ...user,
                status: statuses[memberIds.indexOf(user.id)]
            }));

            // Fetch profile pictures
            const membersWithProfilePics = await Promise.all(membersWithStatus.map(async member => {
                if (member.ProfileURL) {
                    const profilePicUrl = await fetchStorageImage(member.ProfileURL);
                    return { ...member, ProfilePicUrl: profilePicUrl };
                }
                return member;
            }));

            setMembers(membersWithProfilePics);
        } catch (error) {
            console.error('Error removing member:', error);
        }
    };

    const handleContextMenu = (e, member) => {
        e.preventDefault();
        const rect = e.currentTarget.getBoundingClientRect();
        setSelectedMember(member);
        setContextMenuPosition({ x: rect.right, y: rect.bottom });
        setContextMenuVisible((prev) => (!(prev && selectedMember === member)));
      };

    const sortedMembers = [...members].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const filteredMembers = sortedMembers.filter(member => {
        if (filterConfig.key && filterConfig.value) {
            return member[filterConfig.key] === filterConfig.value;
        }
        return true;
    });

    const groupedMembers = filteredMembers.reduce((acc, member) => {
        const key = member[groupBy];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(member);
        return acc;
    }, {});

    const renderRoleIcon = (role) => {
        switch (role) {
            case 'owner':
                return <OwnerIcon />;
            case 'admin':
                return <PublicIcon />;
            case 'student':
                return <StudentIcon />;
            case 'manager':
                return <ManagerIcon />;
            case 'guest':
                return <PublicIcon />;
            default:
                return null;
        }
    };

    if (loading) {
        return <div className={"h-full justify-center items-center flex"}>
            <LoadingSpinner />
        </div>;
    }

    return (
        <div className="py-3 bg-white h-full">
            <div className="px-6 flex justify-between items-center mb-2">
                <div className="flex items-center space-x-1">
                    <div className="p-0.5 flex hover:bg-slate-100 transition rounded cursor-pointer items-center">
                        <img className="w-5 h-5 mr-1.5" src={MembersIcon} alt="Members" />
                        <h1 className="text-sm font-semibold text-black">Manage Team</h1>
                    </div>
                </div>
                <div className="flex items-center space-x-4 h-full">
                    <span className="text-gray-400 text-sm">{members.length} Member(s)</span>
                    <button
                        className="border text-sm text-white rounded-md hover:bg-slate-50 transition p-1.5"
                        onClick={() => setIsSidebarOpen(true)}  // Open the sidebar
                    >
                        <img className="h-5 w-5" src={FilterIcon}/>
                    </button>
                    <button className="border text-sm text-white rounded-md hover:bg-slate-50 transition p-1.5" onClick={() => setShowAddMember(true)}>
                        <img className="h-5 w-5" src={Plus} alt="Add" />
                    </button>

                </div>
            </div>
            <div className="overflow-hidden border-t border-slate-100">
                {Object.keys(groupedMembers).map((key) => (
                    <div key={key}>
                        <div className="bg-slate-100 py-1 text-sm font-semibold px-6 flex items-center">
                            {renderRoleIcon(key)}
                            <span className="ml-2">{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                            <span className="ml-2 text-xs font-semibold bg-slate-200 rounded px-1 text-slate-500">{groupedMembers[key].length}</span>
                        </div>
                        <table className="min-w-full">
                            <tbody>
                                {groupedMembers[key].map((member) => (
                                    <tr key={member.id}
                                        className="relative group border-b border-slate-100 flex items-center">
                                        <td className="py-3 border-slate-100 w-10 pl-6">{renderRoleIcon(member.status)}</td>
                                        <td className="py-3 border-slate-100 w-8 pl-1 ml-4 flex items-center">
                                            {member.ProfilePicUrl ? (
                                                <img src={member.ProfilePicUrl} alt="Profile"
                                                     className="w-6 h-6 rounded-full"/>
                                            ) : (
                                                <div className="w-6 h-6 rounded-full bg-slate-100"></div>
                                            )}
                                        </td>
                                        <td className="py-3 border-slate-100 w-64 text-sm font-medium opacity-75 pl-1 ml-0.5">{member.FirstName} {member.LastName}</td>
                                        <td className={"flex-grow"}></td>
                                        <td className="py-3 border-slate-100 w-64 text-sm font-light text-slate-400 pl-2 text-end mr-6">{member.Email}</td>
                                        {!userIsGuest && <td className="w-6 mr-7 opacity-0 group-hover:opacity-100 justify-center">
                                            <button
                                                className="group flex w-6 h-6 p-1 justify-between rounded-full hover:bg-slate-100 transition items-center"
                                                onClick={(e) => handleContextMenu(e, member)}
                                            >
                                                <div
                                                    className={"w-1 h-1 rounded-full bg-slate-400 group-hover:bg-slate-700 transition"}></div>
                                                <div
                                                    className={"w-1 h-1 rounded-full bg-slate-400 group-hover:bg-slate-700 transition"}></div>
                                                <div
                                                    className={"w-1 h-1 rounded-full bg-slate-400 group-hover:bg-slate-700 transition"}></div>
                                            </button>
                                        </td> }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
            {showAddMember && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg">
                        <h2 className="text-lg font-semibold mb-4">Add New Member</h2>
                        <input
                            className="w-full mb-4 p-2 border rounded"
                            type="email"
                            placeholder="Enter member's email"
                            value={newMemberEmail}
                            onChange={(e) => setNewMemberEmail(e.target.value)}
                        />
                        <input
                            className="w-full mb-4 p-2 border rounded"
                            type="text"
                            placeholder="Enter member's status"
                            value={newMemberStatus}
                            onChange={(e) => setNewMemberStatus(e.target.value)}
                        />
                        <div className="flex justify-end space-x-4">
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={() => setShowAddMember(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-green-500 text-white px-4 py-2 rounded"
                                onClick={handleAddMember}
                            >
                                Add Member
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {contextMenuVisible && (
                <div
                    className="absolute w-40 bg-white shadow-lg rounded-lg z-50 border right-6 overflow-clip"
                    style={{ top: contextMenuPosition.y }}
                >
                    <button
                        className="group w-full text-left px-3 py-2 hover:bg-slate-50 transition flex space-x-1 items-center"
                        onClick={() => {
                            alert(`Member Details: ${JSON.stringify(selectedMember, null, 2)}`);
                            setContextMenuVisible(false);
                        }}
                    >
                        <img src={Details} className={"opacity-30 group-hover:opacity-75 transition"}/>
                        <h1 className={"text-slate-300 group-hover:text-slate-700 transition text-sm font-medium"}>Details</h1>
                    </button>
                    <button
                        className="group w-full text-left px-3 py-2 hover:bg-red-50 transition flex space-x-1 items-center"
                        onClick={() => handleRemoveMember(selectedMember.id)}
                    >
                        <img src={Remove} className={"opacity-30 group-hover:opacity-75 transition"}/>
                        <h1 className={"text-slate-300 group-hover:text-red-700 transition text-sm font-medium"}>Remove</h1>
                    </button>
                </div>
            )}

            {/* Sidebar Menu */}
            <div
                className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg z-50 transform ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform`}
            >
                <div className="px-5 py-3 flex justify-between items-center border-b">
                    <h2 className="text-lg font-semibold">Options</h2>
                    <button onClick={() => setIsSidebarOpen(false)} className={"font-medium text-xl"}>&times;</button>
                </div>
                <div className="p-4 space-y-4">
                    <Dropdown
                        icon={GroupIcon}
                        options={[
                            {value: 'status', label: 'Group by Status'},
                            {value: 'FirstName', label: 'Group by First Name'},
                            {value: 'LastName', label: 'Group by Last Name'},
                        ]}
                        onSelect={handleGroupBy}
                        functionName={"Grouping"}
                    />
                    <Dropdown
                        icon={SortIcon}
                        options={[
                            {value: 'FirstName', label: 'Sort by First Name'},
                            {value: 'LastName', label: 'Sort by Last Name'},
                            {value: 'status', label: 'Sort by Status'},
                        ]}
                        onSelect={handleSort}
                        functionName={"Sort By"}
                    />
                    <Dropdown
                        icon={FilterIcon}
                        options={[
                            {value: 'owner', label: 'Filter by Owners'},
                            {value: 'admin', label: 'Filter by Admins'},
                            {value: 'manager', label: 'Filter by Managers'},
                        ]}
                        onSelect={(value) => handleFilter('status', value)}
                        functionName={"Filter By"}
                    />
                </div>
            </div>
        </div>
    );
};

export default MemberList;
