import React, { useState, useEffect } from 'react';
import MaxImage from "../../assets/images/maxSupport.webp"

const SupportChat = ({ orgId }) => {
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([
        { sender: 'max', text: 'Hello! How can I help you?' }
    ]);
    const [input, setInput] = useState('');

    const handleSendMessage = () => {
        if (input.trim()) {
            setMessages([...messages, { sender: 'user', text: input }]);
            setInput('');
            // Here you would normally send the message to your backend or AI service
        }
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="w-full h-full flex flex-col px-2">
            <div className={"h-16 w-full"}>
                <h1 className={"fixed font-medium text-xl opacity-50 bg-white"}>Chat With Max</h1>
            </div>
            <div className={"w-full h-full flex flex-col justify-end py-10 space-y-2 overflow-y-auto no-scrollbar"}>
                {messages.map((message, index) => (
                    <div key={index} className={`flex ${message.sender === 'max' ? 'justify-start' : 'justify-end'} space-x-2`}>
                        {message.sender === 'max' && <img className={"w-14 h-14 rounded-lg bg-slate-200"} src={MaxImage} alt={"Max"} />}
                        <div className={`rounded-t-3xl ${message.sender === 'max' ? 'rounded-br-3xl rounded-bl-sm bg-primary bg-opacity-25' : 'rounded-bl-3xl rounded-br-sm bg-primary text-white'} w-fit h-fit py-2 px-2.5`}>
                            {message.text}
                        </div>
                    </div>
                ))}
            </div>
            <div className={"w-full h-12 bg-slate-100 rounded-full flex items-center px-4"}>
                <input
                    type="text"
                    className="flex-grow bg-transparent outline-none"
                    value={input}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message..."
                />
                <button className="text-primary font-medium" onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default SupportChat;
