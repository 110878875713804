import React, { useState } from 'react';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: 'How do I reset my password?',
            answer: 'To reset your password, go to the login page and click on "Forgot Password". Enter your email address and follow the instructions sent to your email to reset your password.'
        },
        {
            question: 'How do I update my profile information?',
            answer: 'To update your profile information, go to your account settings and click on "Personal Settings". Here you can update your name, email, phone number, and profile picture.'
        },
        {
            question: 'How do I add a new user to my organization?',
            answer: 'To add a new user to your organization, go to the "Members" section in the admin panel and click on "Add Member". Fill in the required information and click "Submit".'
        },
        {
            question: 'How do I delete my account?',
            answer: 'To delete your account, please contact our support team at support@example.com. They will assist you with the account deletion process.'
        },
        {
            question: 'How do I contact support?',
            answer: 'To contact support, you can email us at support@example.com or call us at (123) 456-7890. Our support team is available Monday to Friday from 9 AM to 5 PM.'
        }
    ];

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="w-full h-full py-4">
            <h1 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h1>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border-b pb-4">
                        <button
                            className="w-full text-left text-lg font-medium text-blue-500 focus:outline-none"
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                        </button>
                        {activeIndex === index && (
                            <div className="mt-2 text-gray-700">
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
