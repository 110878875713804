import React, { useEffect, useRef } from "react";
import Navigation from "../common/Nav";
import HomeIcon from "../../assets/icons/DashboardBlack.svg";
import SitesIcon from "../../assets/icons/SitesBlack.svg";
import DevicesIcon from "../../assets/icons/DevicesBlack.svg";
import POSIcon from "../../assets/icons/AlertsBlack.svg";
import MembersIcon from "../../assets/icons/MembersBlack.svg";
import SearchIcon from "../../assets/icons/SearchBlack.svg";
import AnalyticsIcon from "../../assets/icons/AnalyticsBlack.svg";
import SettingsIcon from "../../assets/icons/SettingsBlack.svg";
import SupportIcon from "../../assets/icons/SupportBlack.svg";
import MiddleburyIcon from "../../assets/images/MiddleburySeal220.gif";
import DemoUser from "../../assets/images/DemoUser.png";
import UsageIcon from "../../assets/icons/UsageIcon.svg"

export default function Hero() {
    const parallaxRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            parallaxRef.current.style.transform = `translateY(${scrollTop * 0.1}px)`;
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const createDots = (rows, cols) => {
        const dots = [];
        const dotSize = 4; // Adjust size of the dots here
        const gap = 20; // Adjust the gap between dots here
        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < cols; j++) {
                dots.push(
                    <div
                        key={`${i}-${j}`}
                        className="absolute bg-blue-500 rounded-full"
                        style={{
                            width: `${dotSize}px`,
                            height: `${dotSize}px`,
                            top: `${i * gap}px`,
                            left: `${j * gap}px`,
                        }}
                    ></div>
                );
            }
        }
        return dots;
    };

    return (
        <div className="flex flex-col items-center w-full h-fit px-4 md:px-16 lg:px-32 xl:px-64 py-6">
            <span className="absolute bottom-40 bg-primary opacity-80 blur-3xl h-20 w-full rotate-12"></span>
            <span className="absolute bottom-40 bg-blue-500 opacity-80 blur-3xl h-20 w-full -rotate-12"></span>
            <span className="absolute -bottom-52 bg-blue-500 opacity-80 blur-3xl h-20 w-full rotate-12"></span>
            <span className="absolute -bottom-52 bg-primary opacity-80 blur-3xl h-20 w-full -rotate-12"></span>
            <div className="absolute w-36 h-36 flex right-8 md:right-48 -bottom-64">
                <div className="w-full h-full justify-center items-center flex rounded-full border-2 border-blue-500 relative">
                    <div className="absolute w-4 h-4 bg-blue-500 rounded-full animate-spin-slow"></div>
                </div>
            </div>
            <div className="invisible md:visible absolute top-96 left-8 md:left-36 w-fit h-fit">
                {createDots(10, 10)}
            </div>
            <Navigation />
            <h1 className="text-4xl lg:text-5xl font-semibold mt-28 cursor-default text-center">
                One Cardless System.
            </h1>
            <h1 className="text-4xl lg:text-5xl font-semibold mt-4 cursor-default text-center">
                Unlimited Possibilities.
            </h1>
            <h1 className="text-sm md:text-md lg:text-lg mt-8 cursor-default text-gray-500 z-10 md:w-136 w-96 text-center">
                A state-of-the-art contactless authentication system bolstered by our web platform to make your organization secure.
            </h1>
            <a href="/signup" className="flex mt-8 z-10">
                <button>
                    <div className="px-4 py-3 rounded-lg bg-black content-center font-medium text-white text-sm hover:bg-black hover:bg-opacity-80 transition ease-in-out">
                        Get Started for Free
                    </div>
                </button>
            </a>
            <div ref={parallaxRef} className="overflow-clip w-full md:h-136 h-152 flex min-w-96 z-20 mt-12 cursor-default relative bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40">
                <div className="w-0 invisible md:w-36 md:visible border-r border-white border-opacity-40 flex flex-col p-2">
                    <div className="flex w-full justify-between mt-2">
                        <div className="flex items-center space-x-1">
                            <img className="h-4 w-4 rounded" src={MiddleburyIcon} alt="Middlebury Icon"/>
                            <h1 className="text-2xs font-medium">Middlebury</h1>
                        </div>
                        <img className="h-4 w-4 rounded-full" src={DemoUser} alt="Demo User"/>
                    </div>
                    <div className="flex flex-col w-full flex-grow py-6 space-y-1.5">
                        <div
                            className="flex space-x-1 opacity-75 items-center pl-1.5 py-0.5 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-md shadow-lg border border-white border-opacity-40">
                            <img className="h-4 w-4 mt-0.5" src={HomeIcon} alt="Home Icon"/>
                            <h1 className="text-xs">Home</h1>
                        </div>
                        <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                            <img className="h-4 w-4 mt-0.5" src={SitesIcon} alt="Sites Icon"/>
                            <h1 className="text-xs">Sites</h1>
                        </div>
                        <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                            <img className="h-4 w-4 mt-0.5" src={DevicesIcon} alt="Devices Icon"/>
                            <h1 className="text-xs">Devices</h1>
                        </div>
                        <div className="flex flex-col pt-4">
                            <h1 className="text-2xs opacity-50 font-medium">SECURITY</h1>
                            <div className="w-full h-fit py-2 space-y-1.5 flex flex-col">
                                <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                    <img className="h-4 w-4 mt-0.5" src={MembersIcon} alt="Members Icon"/>
                                    <h1 className="text-xs">Access</h1>
                                </div>
                                <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                    <img className="h-4 w-4 mt-0.5" src={UsageIcon} alt="Members Icon"/>
                                    <h1 className="text-xs">Usage</h1>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col pt-4">
                            <h1 className="text-2xs opacity-50 font-medium">PAYMENTS</h1>
                            <div className="w-full h-fit py-2 space-y-1.5 flex flex-col">
                                <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                    <img className="h-4 w-4 mt-0.5" src={POSIcon} alt="Analytics Icon"/>
                                    <h1 className="text-xs">Point of Sale</h1>
                                </div>
                                <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                                    <img className="h-4 w-4 mt-0.5" src={AnalyticsIcon} alt="Analytics Icon"/>
                                    <h1 className="text-xs">Analytics</h1>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-grow w-full"></div>
                        <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                            <img className="h-4 w-4 mt-0.5" src={SettingsIcon} alt="Settings Icon"/>
                            <h1 className="text-xs">Settings</h1>
                        </div>
                        <div className="flex space-x-1 opacity-75 items-center ml-1.5">
                            <img className="h-4 w-4 mt-0.5" src={SupportIcon} alt="Support Icon"/>
                            <h1 className="text-xs">Support</h1>
                        </div>
                    </div>
                </div>
                <div className="flex-grow flex flex-col h-full py-1.5 space-y-4 overflow-y-clip mt-2">
                    <div className="w-full h-fit flex justify-between px-0 md:px-8">
                        <div className="w-fit h-fit flex flex-col">
                            <h1 className="text-xl font-semibold">Welcome, Ricky</h1>
                            <h1 className="text-md font-medium opacity-50 text-slate-900">Administrator</h1>
                        </div>
                        <div className="w-fit h-fit">
                            <h1 className="text-lg font-semibold text-slate-400 mt-1">July 12th</h1>
                        </div>
                    </div>
                    <div className="overflow-y-clip h-120 w-full space-y-4 px-0 md:px-8">
                        <div
                            className="w-full h-48 bg-white bg-opacity-30 overflow-clip backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 flex flex-col">
                            <div className="h-6 w-full flex justify-between text-xs p-3">
                                <div className="w-fit h-fit flex">
                                    <h1 className="font-medium">Cashflow</h1>
                                    <h1 className="opacity-30 font-medium mx-1.5">•</h1>
                                    <div className="flex cursor-pointer">
                                        <img className="opacity-30 transition h-4/5" src={AnalyticsIcon} alt="Revenue"/>
                                        <h1 className="opacity-30 transition font-medium">Analytics</h1>
                                    </div>
                                </div>
                                <div className="w-fit h-fit flex items-center">
                                    <h1 className="font-medium">$7,120</h1>
                                    <h1 className="opacity-30 font-medium mx-1.5">• Dec 2024</h1>
                                </div>
                            </div>
                            <div className="w-full h-full mt-10 relative scale-110">
                                <div className="absolute left-1/2 transform -translate-x-1/2 -mt-2 flex">
                                    <h1 className={"text-2xs font-semibold text-blue-900"}>$255.30</h1>
                                </div>
                                <div className="h-1/2 mt-2 border-l opacity-10 border-black border-dashed absolute left-1/2 transform -translate-x-1/2"></div>
                                <div className="absolute h-full left-1/2 transform -translate-x-1/2 mt-24 pt-2.5">
                                    <h1 className={"text-2xs font-medium text-black opacity-25 mb-0"}>Dec. 15</h1>
                                </div>
                                <div
                                    className="h-2 w-2 mt-2 rounded-full bg-white border-blue-800 border-2 absolute left-1/2 transform -translate-x-1/2"></div>
                                <svg
                                    className="w-full h-full"
                                    viewBox="0 0 157 106"
                                    preserveAspectRatio="none"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.548218 5.29462V105.073H156.867V24.2341C153.475 17.9209 145.86 4.46388 142.53 1.14091C138.368 -3.0128 136.98 7.69823 130.043 24.2341C124.493 37.4627 118.173 29.746 115.706 24.2341L103.681 14.3981C101.215 19.6345 95.5418 27.7637 92.5819 18.3895C89.6221 9.01533 82.4074 5.75367 79.17 5.29462C75.2181 9.23193 65.9906 18.5321 60.6958 24.2341C54.0772 31.3616 51.9543 23.5213 46.2098 14.3981C40.4654 5.27487 34.2215 16.2512 30.3503 18.3895C26.479 20.5278 22.6078 10.1216 21.7337 7.69822C20.8595 5.27487 15.7395 8.41098 13.117 7.69822C11.0191 7.12802 10.2478 -0.107535 7.30552 0.265645L0.548218 5.29462Z"
                                        fill="url(#paint0_linear_3011_811)"
                                    />
                                    <defs>
                                        <linearGradient id="paint0_linear_3011_811" x1="78.7075" y1="0.244019"
                                                        x2="78.7075" y2="105.073" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#3b82f6"/>
                                            <stop offset="1" stopColor="#ffffff" stopOpacity="0"/>
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </div>
                        </div>

                        <div className="w-full h-32 flex space-x-4">
                            <div
                                className="w-1/2 h-full bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 p-3.5 flex flex-col justify-between">
                                <div className="text-xs flex">
                                    <h1 className="font-medium mr-1">Total Members</h1>
                                </div>
                                <div className={"h-fit w-full flex flex-col"}>
                                    <div className={"flex"}>
                                        <h1 className={"text-3xl font-medium"}>36</h1>
                                        <h2 className={"text-2xs text-green-800"}>+12</h2>
                                    </div>
                                    <div className={"flex space-x-1"}>
                                        <h1 className={"text-xs font-medium opacity-40"}>Last Week: 24</h1>
                                        <h2 className={"text-2xs text-green-800"}>(+50%)</h2>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w-1/2 h-full bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 p-3.5 flex flex-col justify-between">
                                <div className="text-xs flex h-fit">
                                    <h1 className="font-medium mr-1">First-Time Users</h1>
                                </div>
                                <div className={"h-fit w-full flex flex-col"}>
                                    <div className={"flex"}>
                                        <h1 className={"text-3xl font-medium"}>12</h1>
                                        <h2 className={"text-2xs text-green-800"}>+2</h2>
                                    </div>
                                    <div className={"flex space-x-1"}>
                                        <h1 className={"text-xs font-medium opacity-40"}>Last Week: 10</h1>
                                        <h2 className={"text-2xs text-green-800"}>(+20%)</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-full h-64 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 p-3">
                            <div className={"h-6 w-full flex justify-between text-xs"}>
                                <div className={"w-fit h-fit flex"}>
                                    <h1 className={"font-medium"}>Entry Traffic</h1>
                                    <h1 className={"opacity-30 font-medium mx-1.5"}>•</h1>
                                    <div className={"flex cursor-pointer"}>
                                        <img className={"opacity-30 transition h-4/5"}
                                             src={UsageIcon} alt={"Revenue"}/>
                                        <h1 className={"opacity-30 transition font-medium"}>Usage</h1>
                                    </div>
                                </div>
                                <div className={"w-fit h-fit flex items-center"}>
                                    <h1 className={"font-medium"}>1,248 Entries</h1>
                                    <h1 className={"opacity-30 font-medium mx-1.5"}>• 30 Dec</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="w-0 invisible md:w-60 md:visible border-l border-white border-opacity-40 flex flex-col p-2">
                    <div
                        className="w-full h-6 text-xs flex items-center text-slate-400 px-2 mt-2 space-x-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-md shadow-lg border border-white border-opacity-40">
                        <img className="h-4 w-4 opacity-40" src={SearchIcon} alt="Search"/>
                        <h1>Search for Anything</h1>
                    </div>
                    <div className="flex-grow w-full py-6 flex flex-col">
                        <h1 className="text-2xs opacity-40 mb-1 font-medium">ALERTS</h1>
                        <div className="space-y-4 flex flex-col">
                            <div
                                className="w-full h-fit bg-primary bg-opacity-10 border-l-4 rounded overflow-clip border-primary flex py-1 px-2 space-x-2 shadow">
                                <div className="w-8 h-full p-0.5">
                                <div
                                        className="w-full aspect-square rounded-full bg-primary text-center text-white font-bold text-2xs content-center">$
                                    </div>
                                </div>
                                <div className="flex-grow h-full flex flex-col">
                                    <h1 className="font-medium text-primary text-2xs">Payment Processed</h1>
                                    <p className="text-2xs">MiddExpress Terminal processed a payment from Nikolas
                                        Larson.</p>
                                </div>
                            </div>
                            <div
                                className="w-full h-fit bg-red-600 bg-opacity-10 border-l-4 rounded overflow-clip border-red-600 flex py-1 px-2 space-x-2 shadow">
                                <div className="w-8 h-full p-0.5">
                                    <div
                                        className="w-full aspect-square rounded-full bg-red-600 text-center text-white font-bold text-2xs content-center">!
                                    </div>
                                </div>
                                <div className="flex-grow h-full flex flex-col">
                                    <h1 className="font-medium text-red-600 text-2xs">Alert: Low Battery</h1>
                                    <p className="text-2xs">Front Door Reader at Bicentennial Hall is running low on
                                        battery!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-40 w-full flex flex-col">
                        <h1 className="text-2xs opacity-40 font-medium">DAILY SUMMARY</h1>
                        <p className="text-xs">Bicentennial hall has seen a lot more traffic today, consider making the
                            site public. MiddExpress has seen 45% more sales today than at this point yesterday. You
                            have two low-battery locks at Davis Library that should be addressed.</p>
                    </div>
                </div>
                <div className={"absolute bottom-0 w-full h-16 visible md:invisible bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-t-lg shadow-lg border border-white border-opacity-40 flex flex-col items-center"}>
                    <div className={"flex w-full flex-grow space-x-10 justify-center items-center"}>
                        <div className={"flex flex-col w-fit h-fit items-center"}>
                            <img className={"w-4 h-4"} src={HomeIcon} alt={"Home"}/>
                            <h1 className={"text-2xs opacity-50"}>Home</h1>
                        </div>
                        <div className={"flex flex-col w-fit h-fit items-center"}>
                            <img className={"w-4 h-4"} src={SitesIcon} alt={"Home"}/>
                            <h1 className={"text-2xs opacity-50"}>Sites</h1>
                        </div>
                        <div className={"flex flex-col w-fit h-fit items-center"}>
                            <img className={"w-4 h-4"} src={DevicesIcon} alt={"Home"}/>
                            <h1 className={"text-2xs opacity-50"}>Devices</h1>
                        </div>
                        <div className={"flex flex-col w-fit h-fit items-center"}>
                            <img className={"w-4 h-4"} src={SearchIcon} alt={"Home"}/>
                            <h1 className={"text-2xs opacity-50"}>Search</h1>
                        </div>
                    </div>
                    <div className={"flex w-20 mb-2 bg-slate-400 rounded-full h-0.5"}></div>
                </div>
            </div>
        </div>
    );
}

