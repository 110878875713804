import React, {useEffect} from 'react';
import Hero from "../../components/specific/Hero";
import Footer from "../../components/common/Footer";
import LandingBody from "../../components/specific/LandingBody";

export default function Home(){
    return(
        <div className="w-screen h-fit overflow-clip">
          <div className="w-full h-full">
              <Hero/>
              <LandingBody/>
              <Footer/>
          </div>
        </div>
    )
}
