import React, { useEffect, useState } from "react";
import MembersIcon from "../../assets/icons/MembersBlack.svg";
import { fetchTotalMembers, fetchNewMembersThisWeek } from '../../services/firestoreService'; // Adjust the path as necessary

const MembersWidget = ({ orgId }) => {
    const [totalMembers, setTotalMembers] = useState(0);
    const [newMembersThisWeek, setNewMembersThisWeek] = useState(0);

    useEffect(() => {
        const fetchMemberData = async () => {
            try {
                const totalMembersCount = await fetchTotalMembers(orgId);
                setTotalMembers(totalMembersCount);

                const newMembersCount = await fetchNewMembersThisWeek(orgId);
                setNewMembersThisWeek(newMembersCount);
            } catch (error) {
                console.error("Error fetching member data:", error);
            }
        };

        fetchMemberData();
    }, [orgId]);

    return (
        <div className={"w-full h-48 mt-12 flex space-x-4"}>
            <div className={"rounded-xl shadow-lg bg-white p-4 flex flex-col w-1/2 h-full"}>
                <div className={"h-10 w-full flex justify-between"}>
                    <div className={"w-fit h-fit flex"}>
                        <h1 className={"font-medium"}>Total Members</h1>
                        <h1 className={"opacity-30 font-medium mx-1.5"}>•</h1>
                        <div className={"group flex cursor-pointer"}>
                            <img className={"opacity-30 group-hover:opacity-100 transition"} src={MembersIcon}
                                 alt={"Members"}/>
                            <h1 className={"opacity-30 group-hover:opacity-100 transition font-medium"}>Team</h1>
                        </div>
                    </div>
                </div>
                <div className={"flex-grow flex flex-col justify-end"}>
                    <h1 className={"text-5xl font-semibold"}>{totalMembers}</h1>
                    <h2 className={"text-sm opacity-50"}>Last Week: </h2>
                </div>
            </div>
            <div className={"rounded-xl shadow-lg bg-white p-4 flex flex-col w-1/2 h-full"}>
                <div className={"h-10 w-full flex justify-between"}>
                    <div className={"w-fit h-fit flex"}>
                        <h1 className={"font-medium"}>First-Time Traffic</h1>
                        <h1 className={"opacity-30 font-medium mx-1.5"}>•</h1>
                        <div className={"group flex cursor-pointer"}>
                            <img className={"opacity-30 group-hover:opacity-100 transition"} src={MembersIcon}
                                 alt={"Members"}/>
                            <h1 className={"opacity-30 group-hover:opacity-100 transition font-medium"}>Team</h1>
                        </div>
                    </div>
                </div>
                <div className={"flex-grow flex flex-col justify-end"}>
                    <h1 className={"text-5xl font-semibold"}>{totalMembers}</h1>
                    <h2 className={"text-sm opacity-50"}>Last Week: </h2>
                </div>
            </div>
        </div>
    );
};

export default MembersWidget;

